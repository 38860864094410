import { TableRow, TableRowProps } from "./table-row";
import * as React from "react";
import { ZebraProps } from "./table";
import { FC, Fragment } from "react";
import { getDefaultBorderIncludes } from "./utils";

export interface TableBodyProps extends TableRowProps, Pick<ZebraProps, "zebra"> {
    /**
     * The data associated with the table.
     */
    data?: any[];

    /**
     * Allows control of the very top border of the TableBody to be toggled on and off
     * if there is no header.
     */
    renderTopBorder?: boolean;
}

/**
 * This component displays the data as {@see TableRow}s.
 */
export const TableBody: FC<TableBodyProps> = props => {
    const rowCells: React.ReactNode[] = React.Children.toArray(props.children);
    const { includeLeftBorder, includeBottomBorder, includeRightBorder } = getDefaultBorderIncludes(props);
    const dataRows = props.data ?? [];

    return (
        <Fragment>
            {dataRows.map((data, rowIndex) => (
                <TableRow
                    {...props}
                    key={rowIndex}
                    even={rowIndex % 2 === 0}
                    data={data}
                    includeLeftBorder={includeLeftBorder}
                    includeBottomBorder={includeBottomBorder}
                    includeRightBorder={includeRightBorder}
                    includeTopBorder={rowIndex === 0 ? false : true}
                >
                    {rowCells}
                </TableRow>
            ))}
        </Fragment>
    );
};
