import { Document } from "@react-pdf/renderer";
import { addressData, contactData, generalData, miscellaneousData, personalData } from "./notary-helpers";
import NotaryDataPage from "./notary-pdf-pages/notary-data-page";
import NotaryMiscellaneousPage from "./notary-pdf-pages/notary-miscellaneous-data";
import NotaryRemarksPage from "./notary-pdf-pages/notary-remarks";
import NotaryTitlePage from "./notary-pdf-pages/notary-title-page";

export const NotaryPdfDocument = ({ realEstate, protocolData }) => {
    return (
        <Document>
            <NotaryTitlePage realEstate={realEstate} protocolData={protocolData} />
            <NotaryDataPage
                realEstate={realEstate}
                protocolData={protocolData}
                generalData={generalData(realEstate)}
                personalData={personalData(realEstate)}
                addressData={addressData(realEstate)}
            />

            <NotaryMiscellaneousPage
                realEstate={realEstate}
                protocolData={protocolData}
                contactData={contactData(realEstate)}
                miscellaneousData={miscellaneousData(realEstate)}
            />

            <NotaryRemarksPage realEstate={realEstate} protocolData={protocolData} />
        </Document>
    );
};
