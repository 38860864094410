import realEstateService from "../services/real-estate";
import { setDocUpdated, setRealEstate } from "../slices/real-estate.slice";
import { SaveFormThunkArgs } from "@onpreo/components/src";
import { ImageDetails, RealEstateDocument } from "@onpreo/database/src";
import { createAppAsyncThunk } from "../index";
import { createNotification, enqueueSnackbar } from "@onpreo/slices";

export const $updateRealEstate = createAppAsyncThunk(
    "realEstate/$updateRealEstate",
    async ({ id, value }: SaveFormThunkArgs<RealEstateDocument>, thunkAPI) => {
        const response = await realEstateService.updateRealEstate(id, value);
        thunkAPI.dispatch(setRealEstate(response.data.realEstate));
        // thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        thunkAPI.dispatch(setDocUpdated(true));
        return response.data;
    }
);

export const $uploadAsset = createAppAsyncThunk(
    "real-estate/$uploadAsset",
    async ({ id, file, fileName, isSecure }: { id: string; file: Blob; fileName?: string; isSecure?: boolean }, thunkAPI) => {
        const response = await realEstateService.uploadAsset(id, file, fileName, isSecure ?? false);
        thunkAPI.dispatch(setRealEstate(response.data.realEstate));
        return response.data;
    }
);

export const $removeAsset = createAppAsyncThunk(
    "real-estate/$removeAsset",
    async ({ id, details, isSecure }: { id: string; details: ImageDetails; isSecure?: boolean }, thunkAPI) => {
        try {
            const response = await realEstateService.removeAsset(id, details, isSecure ?? false);
            thunkAPI.dispatch(setRealEstate(response.data.realEstate));
            return response.data;
        } catch (err) {
            return false;
        }
    }
);

export const $addLogoWatermark = createAppAsyncThunk(
    "real-estate/$addLogoWatermark",
    async ({ src, id, logoPosition, opacity }: { src: string; id: string; logoPosition: string; opacity: number }, thunkAPI) => {
        const response = await realEstateService.addLogoWatermark(src, id, logoPosition, opacity);
        return response.data;
    }
);

export const $updatePropertyPhotos = createAppAsyncThunk("real-estate/$updatePropertyPhotos", async ({ id, value }: SaveFormThunkArgs<any>, thunkAPI) => {
    const response = await realEstateService.updatePropertyPhotos(id, value);
    thunkAPI.dispatch(setRealEstate(response.data.realEstate));
    return response.data;
});

export const $updateExposeFields = createAppAsyncThunk("realEstate/$updateExposeFields", async ({ id, value }: { id: string; value: any }, thunkAPI) => {
    const response = await realEstateService.updateExposeFields(id, value);
    thunkAPI.dispatch(setRealEstate(response.data.realEstate));
    return response.data;
});

export const $updateRealEstateRoomsData = createAppAsyncThunk(
    "realEstate/$updateRealEstateRoomsData",
    async ({ id, value }: { id: string; value: any }, thunkAPI) => {
        const response = await realEstateService.updateRoomData(id, value);
        return response.data;
    }
);

export const $getRealEstateValuationDocument = createAppAsyncThunk(
    "realEstate/$getRealEstateValuationDocument",
    async ({ realEstateId }: { realEstateId: string }, thunkAPI) => {
        const response = await realEstateService.getRealEstateValuationDocument(realEstateId);
        return response.data;
    }
);

export const $createDescriptionTexts = createAppAsyncThunk(
    "real-estate/$createDescriptionTexts",
    async ({ id, aiDescriptionsWordCount }: { id: string; aiDescriptionsWordCount?: any }, thunkAPI) => {
        const response = await realEstateService.createDescriptionTexts(id, aiDescriptionsWordCount);
        // thunkAPI.dispatch(setCurrentRealEstate(response.data.realEstate));
        // thunkAPI.dispatch(setRealEstateById({ id, value: response.data.realEstate }));
        // thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);

export const $createDescriptionVariantText = createAppAsyncThunk(
    "real-estate/$createDescriptionVariantText",
    async (
        {
            id,
            variant,
            aiDescriptionsWordCount
        }: { id: string; variant: "title" | "description" | "furnishing" | "location" | "other"; aiDescriptionsWordCount?: any },
        thunkAPI
    ) => {
        const response = await realEstateService.createDescriptionVariantText(id, variant, aiDescriptionsWordCount);
        // thunkAPI.dispatch(setCurrentRealEstate(response.data.realEstate));
        // thunkAPI.dispatch(setRealEstateById({ id, value: response.data.realEstate }));
        // thunkAPI.dispatch(enqueueSnackbar(createNotification("Daten erfolgreich gespeichert", "success")));
        return response.data;
    }
);
