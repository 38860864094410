import { DataTableCell, Table, TableBody, ExposePage, SubTitle, PdfImage, Typo } from "@onpreo/pdf-components";
import { CurrencyShort, formatDateInfo, parseAddress } from "../../utils/formatters";
import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { getCurrencySymbol } from "../real-estate-properties";
import { categoryLabel } from "../pdf-pages/util";

const styles = StyleSheet.create({
    column: {
        width: "100%",
        maxHeight: 70,
        flexDirection: "column",
        flexWrap: "wrap",
        minWidth: "100%",
        height: 70
    },
    flex: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    text: {
        color: "#FFFFFF",
        paddingLeft: 20,
        fontSize: 12,
        fontFamily: "Poppins",
        fontWeight: "normal",
        marginRight: 80
    },
    tableCell: {
        fontSize: 10,
        color: "#383838",
        fontFamily: "Poppins"
    },
    subTitle: {
        color: "black",
        fontSize: 25,
        maxLines: 2,
        fontFamily: "Poppins",
        fontWeight: "semibold"
    },
    price: { padding: 20, paddingLeft: 0, fontSize: 15, fontFamily: "Poppins", fontWeight: "semibold" }
});

const nameMap = {
    balcony: "Balkon",
    elevator: "Fahrstuhl",
    garden: "Garten",
    pool: "Pool",
    sauna: "Sauna",
    storageRoom: "Abstellraum",
    terrace: "Terrasse",
    winterGarden: "Wintergarten",
    nearbyAttractions: "Nahegelegene Sehenswürdigkeiten"
};

const DataOverviewModern = props => {
    let extrasOrder = [];
    if (props.realEstate?.extras) {
        extrasOrder = Object.keys(nameMap).filter(
            item => props.realEstate?.extras[item] === true && props.realEstate?.exposeFields?.[nameMap[item]] === true
        );
    }

    const data = props.data;
    const roomText = props.realEstate?.rooms ? ` mit ${props.realEstate?.rooms} ${props.realEstate?.rooms > 1 ? "Zimmern" : "Zimmer"}` : "";
    const yearText = props.realEstate?.constructionYear ? ` aus dem Jahr ${new Date(props.realEstate?.constructionYear).getFullYear()}` : "";
    const headingText = roomText !== "" ? categoryLabel[props.realEstate.category] + `${roomText},` : categoryLabel[props.realEstate.category] + ",";
    const hasExtras = props.realEstate?.extras && extrasOrder.length > 0;
    const readyForOccupation = props.realEstate?.readyForOccupation
        ? " | " + "Bezugsfrei ab dem" + " " + formatDateInfo(props.realEstate?.readyForOccupation)
        : "";

    return (
        <ExposePage {...props}>
            <SubTitle style={styles.subTitle}>{headingText}</SubTitle>
            <SubTitle style={{ ...styles.subTitle, marginVertical: -15, marginLeft: -7 }}>{yearText}</SubTitle>
            <SubTitle style={{ alignItems: "center", marginLeft: -9 }}>
                <PdfImage src="/location.png" style={{ height: 30, width: 30, color: "black" }} />
            </SubTitle>
            <SubTitle style={{ marginTop: -40, marginLeft: 24, color: "black", fontSize: 16, fontFamily: "Poppins" }}>
                {"in " + parseAddress(props.realEstate?.address)}
            </SubTitle>
            <View
                style={{
                    justifyContent: "center"
                }}
            >
                <PdfImage
                    style={{ width: "80vw", height: "30vh", maxHeight: "30vh", maxWidth: "80vw", objectFit: "cover" }}
                    src={props?.realEstate?.expose?.overviewPic?.src ?? "/background.png"}
                />
            </View>
            <View style={{ backgroundColor: "#F0F1F1", width: "80vw", padding: 30 }}>
                <Typo style={{ ...styles.price, color: props?.workspace?.publication?.primary ?? "#0071E3" }}>
                    {" "}
                    {(props.realEstate.askingPrice.priceAssistant || props.realEstate.askingPrice.evaluation
                        ? CurrencyShort(props.realEstate?.askingPrice?.priceAssistant ?? props.realEstate?.askingPrice?.evaluation) +
                          getCurrencySymbol(props.realEstate)
                        : "auf Anfrage") + readyForOccupation}
                </Typo>
                <Table data={data}>
                    <TableBody renderTopBorder={false}>
                        <DataTableCell weighting={0.25} getContent={r => r.keyLeft} style={styles.tableCell} />
                        <DataTableCell weighting={0.25} getContent={r => r.valLeft} style={styles.tableCell} />
                        <DataTableCell weighting={0.25} getContent={r => r.keyRight} style={styles.tableCell} />
                        <DataTableCell weighting={0.25} getContent={r => r.valRight} style={styles.tableCell} />
                    </TableBody>
                </Table>
            </View>
            <View
                style={{
                    backgroundColor: "black",
                    height: 20,
                    paddingLeft: 0,
                    width: "30vw",
                    marginLeft: 297,
                    position: "absolute",
                    top: "41.4vh"
                }}
            />
            {/* {hasExtras && (
                <View
                    style={{
                        backgroundColor: "black",
                        height: 160,
                        paddingLeft: 0,
                        width: "50vw",
                        marginLeft: 0,
                        position: "absolute",
                        top: "14vh",
                        opacity: 0.8
                    }}
                >
                    <SubTitle
                        style={{ color: "#FFFFFF", padding: 20, fontSize: 14, marginTop: 5, marginLeft: 5, fontFamily: "Poppins", fontWeight: "semibold" }}
                    >
                        Ausstattung
                    </SubTitle>
                    <View style={styles.flex}>
                        <View style={styles.column}>
                            {extrasOrder.map((key, index) => {
                                return (
                                    <Typo key={index} style={styles.text}>
                                        {" "}
                                        {"•" +
                                            " " +
                                            " " +
                                            `${nameMap[key] === "Nahegelegene Sehenswürdigkeiten" ? "Sehenswürdigkeiten" : nameMap[key]}`}{" "}
                                    </Typo>
                                );
                            })}
                        </View>
                    </View>
                </View>
            )} */}
        </ExposePage>
    );
};

export default DataOverviewModern;
