import { StyleSheet, View } from "@react-pdf/renderer";
import { ExposePage, SubTitle, Typo, PdfImage } from "@onpreo/pdf-components";

const IMG_WIDTH = 220;
const IMG_WIDTH_BIG = 340;
const IMG_HEIGHT = 120;
const IMG_HEIGHT_BIG = 160;

const styles = StyleSheet.create({
    half: {
        width: "47%"
    },
    full: {
        width: "100%"
    },
    flex: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    section: {
        width: "100%",
        justifyContent: "space-between"
        // borderTop: 1.5,
        // borderColor: "#a6a6a6"
    },
    column: {
        width: "55%"
    }
});

export const BigImages = ({ imgs, showCaption, skip = false, isContained, ...props }) => {
    return (
        // <View style={{ ...styles.section }}>
        <>
            {imgs.map((photo, index) => {
                const width = IMG_WIDTH_BIG;
                const height = IMG_HEIGHT_BIG;
                const align = "center";

                const doBreak = skip ? true : index > 0;
                const split = photo?.img?.filename.split(".");
                const fileType = split[split.length - 1];
                const fileName = photo?.img?.filename.replace(`.${fileType}`, "");
                return (
                    <>
                        <View
                            key={photo?.img?.id}
                            style={{
                                ...styles.full,
                                ...styles.flex,
                                paddingBottom: 8,
                                justifyContent: align
                            }}
                            break={doBreak && index % 3 === 0}
                        >
                            <PdfImage
                                key={photo?.img?.id}
                                style={{ width, height, maxHeight: height, maxWidth: width, objectFit: isContained ? "contain" : "cover" }}
                                src={photo?.img?.src}
                            />
                        </View>
                        {showCaption === "Ja" && <Typo style={{ textAlign: "center", paddingBottom: 8 }}>{fileName}</Typo>}
                    </>
                );
            })}
        </>
        // </View>
    );
};

export const SmallImages = ({ imgs, showCaption, skip = false, pics, ...props }) => {
    let uploadedPics = pics - imgs.length;
    return (
        // <View style={{ ...styles.flex, ...styles.section }}>
        <>
            {imgs.map((photo, index) => {
                const width = IMG_WIDTH;
                const height = IMG_HEIGHT;
                const align = index % 2 === 0 ? "flex-start" : "flex-end";
                const doBreak = skip ? true : index > 0;
                const split = photo?.img?.filename.split(".");
                const fileType = split[split.length - 1];
                const fileName = photo?.img?.filename.replace(`.${fileType}`, "");

                return (
                    <View style={{ width: "50%", paddingBottom: 16, textAlign: "center" }} key={photo?.img?.id}>
                        {/* <View
                            key={photo?.img?.id}
                            style={{
                                ...styles.flex,
                                ...styles.half,
                                paddingBottom: 16,
                                justifyContent: align,
                                width: "50%"
                            }}
                            break={doBreak && showCaption === "Ja" ? index % 6 === 0 : index % 8 === 0}
                        > */}
                        <PdfImage key={photo?.img?.id} style={{ maxHeight: height, maxWidth: width, objectFit: "cover" }} src={photo?.img?.src} />
                        {showCaption === "Ja" && <Typo style={{ paddingTop: 16 }}>{fileName}</Typo>}
                        {/* </View> */}
                    </View>
                );
            })}
        </>
        // </View>
    );
};

const Impressions = ({ ...props }) => {
    const photos = props.realEstate?.expose?.propertyPhotos;
    const photosOrder =
        photos && props.realEstate?.expose?.propertyPhotosOrder && props.realEstate?.expose?.propertyPhotosOrder.length > 0
            ? props.realEstate?.expose?.propertyPhotosOrder.filter(
                  cat => props.realEstate?.expose?.propertyPhotos?.[cat]?.length !== 0 && cat !== "title" && cat !== "highlights" && cat !== "floorPlan"
              )
            : Object.keys(photos);

    const highlightPhotos = props.realEstate?.expose?.propertyPhotos?.highlights;
    const showCaption = props.realEstate?.workspace?.extras?.showCaption;
    let pics = 0;

    return (
        <ExposePage {...props}>
            <View wrap>
                {highlightPhotos.length === 0 && <SubTitle style={{ paddingBottom: 8, textAlign: "center" }}>Impressionen</SubTitle>}
                <View style={{ ...styles.flex, marginTop: showCaption === "Nein" ? 0 : 20 }} wrap>
                    {photos &&
                        photosOrder.map((category, index) => {
                            pics = photos[category].length + pics;
                            return <SmallImages key={index + "_image"} imgs={photos[category]} showCaption={showCaption} pics={pics} />;
                        })}
                </View>
            </View>

            {/*<View wrap>*/}
            {/*    {photoCategories.map((cat, index) => {*/}
            {/*        if (props.realEstate?.expose?.propertyPhotos?.[cat]?.length === 0 || cat === "title") return;*/}
            {/*        return (*/}
            {/*            <View key={cat} style={{ marginTop: 0 }}>*/}
            {/*                /!*<Typo style={{ marginBottom: 4 }}>{keyLable[cat]}</Typo>*!/*/}
            {/*                <View style={{ ...styles.flex, ...styles.section }} break={index > 0}>*/}
            {/*                    {props.realEstate?.expose?.propertyPhotos?.[cat]?.map((photo, index) => {*/}
            {/*                        const isBig = cat === "highlights" || cat === "title" || cat === "floorPlan";*/}
            {/*                        const width = isBig ? IMG_WIDTH_BIG : IMG_WIDTH;*/}
            {/*                        const height = isBig ? IMG_HEIGHT_BIG : IMG_HEIGHT;*/}
            {/*                        const align = isBig ? "center" : index % 2 === 0 ? "flex-start" : "flex-end";*/}

            {/*                        return (*/}
            {/*                            <View*/}
            {/*                                key={photo?.img?.id}*/}
            {/*                                style={{*/}
            {/*                                    ...styles.flex,*/}
            {/*                                    ...(isBig ? styles.full : styles.half),*/}
            {/*                                    paddingBottom: 16,*/}
            {/*                                    justifyContent: align*/}
            {/*                                }}*/}
            {/*                            >*/}
            {/*                                <PdfImage*/}
            {/*                                    key={photo?.img?.id}*/}
            {/*                                    style={{ width, height, maxHeight: height, maxWidth: width, objectFit: "cover" }}*/}
            {/*                                    src={photo?.img?.src}*/}
            {/*                                />*/}
            {/*                            </View>*/}
            {/*                        );*/}
            {/*                    })}*/}
            {/*                </View>*/}
            {/*            </View>*/}
            {/*        );*/}
            {/*    })}*/}
            {/*</View>*/}
        </ExposePage>
    );
};

export default Impressions;
