import { ExposePage, SubTitle, Typo } from "@onpreo/pdf-components";
import { View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    section: {
        margin: "0 0 8px 0"
    },
    title: {
        paddingBottom: 4
    }
});

const NotaryRemarksPage = props => {
    return (
        <ExposePage {...props}>
            <SubTitle>Zusätzliche Anmerkungen des Eigentümers</SubTitle>
            <View style={styles.section}>
                <Typo>{props.realEstate?.notary?.remarks}</Typo>
            </View>
        </ExposePage>
    );
};

export default NotaryRemarksPage;
