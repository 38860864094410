import { View } from "@react-pdf/renderer";
import { ExposePage, SubTitle, Title, PdfImage } from "@onpreo/pdf-components";
import { parseAddress } from "../notary-helpers";

const NotaryTitlePage = ({ ...props }) => {
    return (
        <ExposePage {...props}>
            <View style={{ marginTop: 24, marginBottom: 16 }}>
                <Title style={{ textAlign: "center" }}>Vertragsdaten zur Immobilie</Title>
                <SubTitle style={{ textAlign: "center", padding: 0 }}>{props.realEstate.address.zip + " " + props.realEstate.address.town}</SubTitle>
                <SubTitle style={{ textAlign: "center", padding: 0 }}>{parseAddress(props.realEstate.address)}</SubTitle>
            </View>
        </ExposePage>
    );
};

export default NotaryTitlePage;
