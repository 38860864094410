import { Document } from "@react-pdf/renderer";
import DataOverview from "./data-overview";
import ContactDetails from "./contact-details";
import Cover from "./cover";
import Impressions from "./impressions";
import Description from "./description";
import FloorPlan from "./floor-plan";
import Highlights from "./highlights";
import RoomTableDataClean from "./room-table-clean";

const OldDocument = ({ realEstate, workspace, data, logoSrc, user, mapSrc, roomTableDataClean }) => {
    const highlightPhotos = realEstate?.expose?.propertyPhotos?.highlights;
    const floorPlanPhotos = realEstate?.expose?.propertyPhotos?.floorPlan;

    return (
        <Document>
            <Cover realEstate={realEstate} workspace={workspace} logoSrc={logoSrc} user={user} />
            <DataOverview realEstate={realEstate} data={data} workspace={workspace} logoSrc={logoSrc} user={user} />
            <Description realEstate={realEstate} workspace={workspace} logoSrc={logoSrc} user={user} mapSrc={mapSrc} />
            {highlightPhotos && highlightPhotos.length > 0 && <Highlights realEstate={realEstate} workspace={workspace} logoSrc={logoSrc} user={user} />}
            <Impressions realEstate={realEstate} workspace={workspace} logoSrc={logoSrc} user={user} />
            {floorPlanPhotos && floorPlanPhotos.length > 0 && <FloorPlan realEstate={realEstate} workspace={workspace} logoSrc={logoSrc} user={user} />}
            {realEstate?.expose?.roomTable?.show && roomTableDataClean.length > 0 && (
                <RoomTableDataClean realEstate={realEstate} workspace={workspace} logoSrc={logoSrc} user={user} roomTableDataClean={roomTableDataClean} />
            )}
            <ContactDetails realEstate={realEstate} workspace={workspace} logoSrc={logoSrc} user={user} />
        </Document>
    );
};

export default OldDocument;
