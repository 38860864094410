import { StyleSheet, View } from "@react-pdf/renderer";
import { ExposePage, SubTitle, Typo, PdfImage } from "@onpreo/pdf-components";

const keyLable = {
    title: "Titelbild",
    highlights: "Highlights",
    livingRoom: "Wohnzimmer",
    bedroom: "Schlafzimmer",
    kitchen: "Küche",
    bathroom: "Badezimmer",
    outdoor: "Außenbereich",
    rooms: "Sonstige Räume",
    floorPlan: "Grundriss",
    otherPhotos: "Weitere Bilder"
};

const IMG_WIDTH_BIG = "80vw";
const IMG_HEIGHT_BIG = "30vh";

const styles = StyleSheet.create({
    half: {
        width: "47%"
    },
    full: {
        width: "100%"
    },
    flex: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    section: {
        width: "100%",
        justifyContent: "space-between"
    },
    header: { textAlign: "left", color: "black", fontSize: 20, padding: 5, fontFamily: "Poppins", fontWeight: "semibold" },
    caption: { textAlign: "center", display: "flex", width: "100%", fontSize: 10, fontFamily: "Poppins" }
});

export const BigImages = ({ imgs, showCaption, category, pics, ...props }) => {
    let uploadedPics = pics - imgs.length;
    return (
        <>
            {imgs.map((photo, index) => {
                const width = IMG_WIDTH_BIG;
                const height = IMG_HEIGHT_BIG;
                const align = "center";

                const split = photo?.img?.filename.split(".");
                const fileType = split[split.length - 1];
                const fileName = photo?.img?.filename.replace(`.${fileType}`, "");
                return (
                    <View
                        key={photo?.img?.id}
                        style={{
                            ...styles.full,
                            justifyContent: align,
                            paddingBottom: 8
                        }}
                        break={uploadedPics === 0 && index === 0 ? false : (uploadedPics + index + 1) % 2 !== 0}
                    >
                        {index === 0 && (
                            <SubTitle key={index + "_title"} style={styles.header}>
                                {keyLable[category]}
                            </SubTitle>
                        )}
                        <PdfImage
                            key={photo?.img?.id}
                            style={{
                                maxHeight: height,
                                maxWidth: width,
                                objectFit: "cover"
                            }}
                            src={photo?.img?.src}
                        />
                        {showCaption === "Ja" && <Typo style={styles.caption}>{fileName}</Typo>}
                    </View>
                );
            })}
        </>
    );
};

const ImpressionsModern = ({ ...props }) => {
    const showCaption = props.workspace?.extras?.showCaption;
    const photos = props.realEstate?.expose?.propertyPhotos;
    const photosOrder =
        photos && props.realEstate?.expose?.propertyPhotosOrder && props.realEstate?.expose?.propertyPhotosOrder.length > 0
            ? props.realEstate?.expose?.propertyPhotosOrder.filter(cat => props.realEstate?.expose?.propertyPhotos?.[cat]?.length !== 0 && cat !== "title")
            : Object.keys(photos);

    let pics = 0;

    return (
        <ExposePage {...props}>
            {photos &&
                photosOrder.map((category, index) => {
                    if (category !== "title" && photos[category].length > 0) {
                        pics = photos[category].length + pics;
                        return <BigImages key={index + "_image"} imgs={photos[category]} showCaption={showCaption} category={category} pics={pics} />;
                    }
                })}
        </ExposePage>
    );
};

export default ImpressionsModern;
