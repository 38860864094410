import ReactPDF, { Font } from "@react-pdf/renderer";
import { NotaryPdfDocument } from "./notary-document";
import { RealEstateDocument } from "@onpreo/database";

export const generateNotaryPDF = async (realEstate: RealEstateDocument, protocolData) => {
    try {
        Font.registerHyphenationCallback(word => [word]);
        const stream = await ReactPDF.renderToStream(<NotaryPdfDocument realEstate={realEstate} protocolData={protocolData} />);

        return new Promise<Buffer>(function (resolve, reject) {
            const buffers: any[] = [];
            stream.on("data", data => {
                buffers.push(data);
            });
            stream.on("end", () => {
                resolve(Buffer.concat(buffers));
            });
            stream.on("error", reject);
        });
    } catch (err) {
        throw new Error(err);
    }
};
