import { StyleSheet, View } from "@react-pdf/renderer";
import { ExposePage, PdfMapImage, SubTitle, Typo } from "@onpreo/pdf-components";

const styles = StyleSheet.create({
    section: {
        margin: "0 0 8px 0"
    },
    title: {
        paddingBottom: 4
    }
});

const Description = props => {
    return (
        <ExposePage {...props} wrap>
            {props.realEstate.expose?.notes?.description && (
                <View style={styles.section}>
                    <SubTitle style={styles.title}>Beschreibung</SubTitle>
                    <Typo>{props.realEstate.expose?.notes?.description}</Typo>
                </View>
            )}
            {props.realEstate.expose?.notes?.location && (
                <View style={styles.section}>
                    <SubTitle style={styles.title}>Lage</SubTitle>
                    <Typo>{props.realEstate.expose?.notes?.location}</Typo>
                </View>
            )}
            {props?.realEstate?.expose?.showMap && props?.mapSrc && (
                <PdfMapImage
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        maxHeight: 200,
                        marginLeft: 0,
                        width: "80vw",
                        maxWidth: "80vw",
                        objectFit: "cover",
                        marginTop: 30,
                        marginBottom: 20
                    }}
                    src={props.mapSrc}
                />
            )}
            {props.realEstate.expose?.notes?.furnishing && (
                <View style={styles.section} break={props?.realEstate?.expose?.showMap && props?.mapSrc}>
                    <SubTitle style={styles.title}>Ausstattung</SubTitle>
                    <Typo>{props.realEstate.expose?.notes?.furnishing}</Typo>
                </View>
            )}
            {props.realEstate.expose?.notes?.other && (
                <View style={styles.section}>
                    <SubTitle>Zusätzliche Informationen</SubTitle>
                    <Typo>{props.realEstate.expose?.notes?.other}</Typo>
                </View>
            )}
        </ExposePage>
    );
};

export default Description;
