import ReactPDF, { Page, StyleSheet, View, Font } from "@react-pdf/renderer";
import { PageLayout } from "./page-layout";
import { FC } from "react";

// NOTE: although this is a viable option, we would need a source for the font, alternative (at least currently) is to just use
// Helvetica-Bold

/* also register helvetica and bold helvetica
 * Font.register({
 *     family: "Helvetica",
 *     fonts: [{ fontWeight: 400 }, { fontWeight: 600 }]
 * }); */

const styles = StyleSheet.create({
    page: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        paddingHorizontal: 64,
        // paddingVertical: "2vh",
        paddingVertical: "6vh",
        fontFamily: "Helvetica",
        fontStyle: "normal",
        lineHeight: 1.4
    }
});

export const BlankPage: FC<ReactPDF.PageProps> = ({ children, ...props }) => {
    return (
        <Page size={"A4"} style={{ ...styles.page }} {...props}>
            <View style={{ minHeight: 620 }}>{children}</View>
        </Page>
    );
};

export const ExposePage: FC<ReactPDF.PageProps> = props => {
    return (
        <Page size={"A4"} style={{ ...styles.page, paddingBottom: 60 }} {...props}>
            <PageLayout {...props}>
                <View style={{ minHeight: 620, marginBottom: 10 }}>{props.children}</View>
            </PageLayout>
        </Page>
    );
};
