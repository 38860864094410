export const categoryLabel = {
    ETW: "Wohnung",
    EFH: "Einfamilienhaus",
    MFH: "Mehrfamilienhaus",
    GRD: "Grundstück"
};

export const constructionLabel = {
    // For EFH and MFH
    DOPPELHAUS: "Doppelhaus",
    FREISTEHEN: "Freistehend",
    REIHEN_ENDHAUS: "Reihenendhaus",
    REIHEN_MITTELHAUS: "Reihenmittelhaus",
    BUNGALOW: "Bunglaow",
    EINFAMILIENHAUS: "Einfamilienhaus",
    // For ETW only
    LOFT: "Loft",
    PENTHOUSE: "Penthouse",
    SOUTERRAIN: "Souterrain",
    ERDGESCHOSSWOHNUNG: "Erdgeschosswohnung",
    ETAGENWOHNUNG: "Etagenwohnung",
    MAISONETTE: "Maisonette",
    TERRASSENWOHNUNG: "Terassenwohnung",
    DACHGESCHOSS: "Dachgeschoss",
    // Only ETW in Germany
    HOCHPARTERRE: "Hochparterre",
    // Only ETW in Austria
    DACHGESCHOSSPENTHOUSE: "Dachgeschosspenthouse",
    MITGARTENTEIL: "Mitgartenteil"
};

export function getFontSize(length: number) {
    if (length < 22) return 40;
    else if (length >= 50) return 28;
    else return 32;
}

export function getPositionTop(length: number) {
    if (length === 0) return 106;
    else if (length < 22) return 163;
    else if (length === 22) return 150;
    else if (length > 22 && length < 50) return 195;
    // else if (length >= 35 && length < 50) return 195;
    else return 185;
}

export function getPositionBottom(length: number) {
    if (length === 0) return 126;
    else if (length < 22) return 183;
    else if (length === 22) return 170;
    else if (length > 22 && length < 50) return 215;
    // else if (length >= 35 && length < 50) return 215;
    else return 205;
}

export const conditionRealEstate = {
    RENOVATED: "Renoviert",
    REFURBISHED: "Saniert",
    MAINTAINED: "Gepflegt",
    NEW: "Neu",
    TO_BE_RENOVATED: "Sanierungsbedüftig"
};

export const services = {
    EINFACH: "Einfach",
    EINFACH_MITTEL: "Einfach Mittel",
    MITTEL: "Mittel",
    MITTEL_GEHOBEN: "Mittel Gehoben",
    GEHOBEN: "Gehoben",
    GEHOBEN_STARK_GEHOBEN: "Gehoben Stark Gehoben",
    STARK_GEHOBEN: "Stark Gehoben"
};

export const heating = {
    FUSSBODENHEIZUNG: "Fussbodenheizung",
    EINZELOEFEN: "Einzelöfen",
    ZENTRALHEIZUNG: "Zentralheizung",
    SONSTIGE: "Sonstige"
};

export const energyType = {
    NO_INFORMATION: "keine Angabe",
    GOETHERMAL: "Erdwärme",
    SOLAR_HEATING: "Solar",
    PELLET_HEATING: "Holzpellets",
    GAS: "Gas",
    OIL: "Öl",
    DISTRICT_HEATING: "Fernwärme",
    ELECTRICITY: "Strom",
    COAL: "Kohle",
    ACID_GAS: "Erdgas leicht",
    SOUR_GAS: "Erdgas leicht",
    LIQUID_GAS: "Flüssiggas",
    STEAM_DISTRICT_HEATING: "Fernwärme Dampf",
    WOOD: "Holz",
    WOOD_CHIPS: "Holz-Hackschnitzel",
    COAL_COKE: "Kohle/Koks",
    LOCAL_HEATING: "Nahwärme",
    HEAT_SUPPLY: "Wärmelieferung",
    BIO_ENERGY: "Bioenergie",
    WIND_ENERGY: "Windenergie",
    HYDRO_ENERGY: "Wasserenergie",
    ENVIRONMENTAL_THERMAL_ENERGY: "Umweltwärme",
    COMBINED_HEAT_AND_POWER_FOSSIL_FUELS: "KWK fossil",
    COMBINED_HEAT_AND_POWER_RENEWABLE_ENERGY: "KWK erneuerbar",
    COMBINED_HEAT_AND_POWER_REGENERATIVE_ENERGY: "KWK regenerativ",
    COMBINED_HEAT_AND_POWER_BIO_ENERGY: "KWK Bio"
};
