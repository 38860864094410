import { View } from "@react-pdf/renderer";
import { PdfImage, ExposePage, SubTitle, Title } from "@onpreo/pdf-components";
import { CurrencyShort, parseAddress } from "../../utils/formatters";
import { categoryLabel, getFontSize } from "./util";

const Cover = ({ ...props }) => {
    const descriptionLength = props.realEstate?.description?.length ?? categoryLabel[props.realEstate?.category].length ?? 0;
    return (
        <ExposePage {...props}>
            <View style={{ marginTop: 24, marginBottom: 16 }}>
                <Title style={{ textAlign: "center", fontSize: getFontSize(descriptionLength), maxLines: 2 }}>
                    {props.realEstate?.description ?? categoryLabel[props.realEstate?.category]}
                </Title>
                {/*<SubTitle style={{ textAlign: "center", padding: 0 }}>{props.realEstate.address.zip + " " + props.realEstate.address.town}</SubTitle>*/}
                <SubTitle style={{ textAlign: "center", padding: 0 }}>{parseAddress(props.realEstate.address)}</SubTitle>
                <SubTitle style={{ textAlign: "center", padding: 0 }}>
                    {props.realEstate.askingPrice.priceAssistant || props.realEstate.askingPrice.evaluation
                        ? CurrencyShort(props.realEstate.askingPrice.priceAssistant ?? props.realEstate.askingPrice.evaluation) + " €"
                        : ""}
                </SubTitle>
            </View>
            <PdfImage
                style={{
                    display: "flex",
                    justifyContent: "center",
                    maxHeight: 384,
                    marginLeft: -64,
                    width: "100vw",
                    maxWidth: "100vw",
                    right: 0,
                    left: 0,
                    top: 0,
                    bottom: 0,
                    objectFit: "cover",
                    marginTop: 30
                }}
                src={props.realEstate.expose?.propertyPhotos?.title?.length > 0 ? props.realEstate.expose?.propertyPhotos?.title[0]?.img?.src : undefined}
            />
        </ExposePage>
    );
};

export default Cover;
