import React, { FC } from "react";
import { PdfImage } from "./pdf-image";
import { PageLayoutProps } from "./page-layout";
import { View } from "@react-pdf/renderer";

const defaultLogoSizes = {
    small: { transform: "scale(0.33, 0.33)", marginHorizontal: -70 },
    medium: { transform: "scale(1, 1)", marginHorizontal: -20 },
    large: { transform: "scale(1.5, 1.5)", marginHorizontal: 20 }
};

export const PageHeader: FC<PageLayoutProps> = ({ realEstate, workspace, logoSrc, type = "clean" }) => {
    const logoSize = workspace?.extras?.logoSize;

    return (
        <View
            style={{
                color: "grey",
                height: type === "modern" ? 65 : 100,
                display: "flex",
                justifyContent: "center",
                width: "100%"
            }}
            fixed
        >
            <PdfImage
                style={{
                    maxWidth: 200,
                    height: 45,
                    transform: defaultLogoSizes[logoSize]?.["transform"] ?? "scale(1,1)",
                    maxHeight: 45,
                    marginBottom: 24,
                    marginHorizontal: realEstate?.expose?.designType === "modern" ? defaultLogoSizes[logoSize]?.["marginHorizontal"] ?? -20 : 136,
                    objectFit: "contain",
                    paddingLeft: 0
                }}
                // src={realEstate?.workspace?.publication?.images?.length > 0 ? realEstate?.workspace?.publication?.images[0].src : ""}
                src={logoSrc}
            />
        </View>
    );
};
