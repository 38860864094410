import { createSelector } from "reselect";
import ReduxState from "../models";

export const getRealEstateObj = (state: ReduxState) => state.realEstate;

export const getRealEstate = createSelector(getRealEstateObj, re => re.realEstate);

export const getRealEstateId = createSelector(getRealEstate, realEstate => (realEstate as any)?._id);

export const getRealEstateWorkspace = createSelector(getRealEstate, realEstate => realEstate.workspace);

export const getAddress = createSelector(getRealEstate, realEstate => realEstate.address);

export const getAskingPrice = createSelector(getRealEstate, realEstate => realEstate.askingPrice);

export const getEstimate = createSelector(getRealEstate, realEstate => realEstate.askingPrice?.estimate);

export const getLivingArea = createSelector(getRealEstate, realEstate => realEstate.livingArea);

export const getCommercialSpace = createSelector(getRealEstate, realEstate => realEstate.commercialSpace);

export const getPlotArea = createSelector(getRealEstate, realEstate => realEstate.plotArea);

export const getFloors = createSelector(getRealEstate, realEstate => realEstate.floor);

export const getFloorNumber = createSelector(getRealEstate, realEstate => realEstate.floorNumber);

export const getRooms = createSelector(getRealEstate, realEstate => realEstate.rooms);

export const getYear = createSelector(getRealEstate, realEstate => realEstate.constructionYear);

export const getCondition = createSelector(getRealEstate, realEstate => realEstate.condition);

export const getHasBasement = createSelector(getRealEstate, realEstate => realEstate.basement);

export const getHasParking = createSelector(getRealEstate, realEstate => realEstate.outdoorParkingSpace);

export const getExtras = createSelector(getRealEstate, realEstate => realEstate.extras);

export const getHasBalcony = createSelector(getRealEstate, realEstate => realEstate.extras?.balcony);

export const getHasTerrace = createSelector(getRealEstate, realEstate => realEstate.extras?.terrace);

export const getHasGarden = createSelector(getRealEstate, realEstate => realEstate.extras?.garden);

export const getAdditionalFeatures = createSelector(getRealEstate, realEstate => realEstate.additionalFeatures);

export const getCustomServices = createSelector(getRealEstate, realEstate => realEstate.customServices);

export const getPotentialBuyers = createSelector(getRealEstate, realEstate => realEstate.potentialBuyers);

export const getMaintenanceYear = createSelector(getRealEstate, realEstate => realEstate.maintenanceYear);

export const getRealEstateConstruction = createSelector(getRealEstate, realEstate => realEstate.construction);

export const getLastRenovated = createSelector(getRealEstate, realEstate => realEstate.lastRenovated);

export const getEquipment = createSelector(getRealEstate, realEstate => realEstate.equipment);

export const getEquipmentHeating = createSelector(getRealEstate, realEstate => realEstate.equipment?.heating);

export const getProvisionRate = createSelector(getRealEstate, realEstate => realEstate.provisionRate);

export const getRent = createSelector(getRealEstate, realEstate => realEstate.rent);

export const getDesignType = createSelector(getRealEstate, realEstate => realEstate?.expose?.designType ?? "clean");

export const getRealEstateExposePhotosOrder = createSelector(getRealEstate, realEstate => realEstate?.expose?.propertyPhotosOrder);

export const getWatermarkeUpdateFlag = createSelector(getRealEstateObj, re => re.updateAllWatermarks);

export const getDocUpdated = createSelector(getRealEstateObj, re => re.docUpdated);
