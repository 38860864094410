import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Button, createStyles, Grid, makeStyles, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import dynamic from "next/dynamic";
import { LoadingButton, createSaveDirtyFieldsForm } from "@onpreo/components";
import { PriceHubbleValuation, RealEstateDocument, Valuation, ValuationDocument } from "@onpreo/database";
import { getDesignType, getRealEstate, getRealEstateWorkspace } from "../store/selectors/real-estate.selectors";
import {
    $getRealEstateValuationDocument,
    $removeAsset,
    $updateExposeFields,
    $updateRealEstate,
    $updateRealEstateRoomsData,
    $uploadAsset
} from "../store/thunks/real-estate.thunk";
import CardLoader from "./card-loader";
import { useFormState } from "react-hook-form";
import { useRouter } from "next/router";
import { $updateExtras } from "../store/thunks/workspace.thunk";
import { useReduxSelector } from "../store/selectors";
import {
    EnergyDataTable,
    GeneralDataTable,
    RealEstateData,
    getCurrencySymbol,
    getEnergyConsumption,
    getEnergyData,
    getProvisionBuyer,
    getProvisionData,
    getProvisionSeller,
    reArrange,
    reArrangeRoomData,
    reArrangeRoomDataClean
} from "./real-estate-properties";
import OldDocument from "./pdf-pages";
import NewDocument from "./modern-pdf-pages";
import { CurrencyShort } from "@onpreo/pdf-components/src/notary-pdf/notary-helpers";
import { categoryLabel, constructionLabel, conditionRealEstate, heating, energyType, services } from "./pdf-pages/util";
import { getUser } from "../store/selectors/user-selector";
import LoadingDialog from "./loading-dialog";
import { pdf } from "@react-pdf/renderer";
import StickyHeader from "./sticky-header";
import { MAPBOX_ACCESS_TOKEN } from "../utils/secrets";
import { useAppDispatch } from "../store";

const PdfPreview = dynamic(() => import("./pdf-preview"));

const useStyles = makeStyles(theme =>
    createStyles({
        itemGrid: {
            padding: 0
        }
    })
);

const cardTypes = ["design", "descriptions", "pictures", "data"];

const useRealEstateForm = createSaveDirtyFieldsForm(getRealEstate, $updateRealEstate);

export const getNewValues = realEstateKeys => {
    let allValues = {};
    Object.values(realEstateKeys).forEach(item => {
        if (item) {
            if (item["val"]) allValues[item["key"]] = true;
            else allValues[item["key"]] = false;
        }
    });
    return allValues;
};

const defaultWordCount = { description: 100, furnishing: 100, location: 100, other: 100 };

export const selector = (realEstate: RealEstateDocument) => ({
    description: realEstate?.description,
    notes: realEstate?.expose?.notes,
    designType: realEstate?.expose?.designType ?? "clean",
    exposeFields: realEstate?.exposeFields ?? getNewValues(RealEstateData(realEstate)),
    extras: realEstate?.workspace?.extras,
    propertyPhotos: realEstate?.expose?.propertyPhotos,
    showMap: realEstate?.expose?.showMap,
    roomTable: realEstate?.expose?.roomTable,
    overviewPic: realEstate?.expose?.overviewPic,
    aiDescriptionsWordCount: {
        description: realEstate?.aiDescriptionsWordCount?.description ?? defaultWordCount.description,
        furnishing: realEstate?.aiDescriptionsWordCount?.furnishing ?? defaultWordCount.furnishing,
        location: realEstate?.aiDescriptionsWordCount?.location ?? defaultWordCount.location,
        other: realEstate?.aiDescriptionsWordCount?.other ?? defaultWordCount.other
    }
});

const setter = (value: ReturnType<typeof selector>) => {
    return {
        description: value?.description,
        designType: value?.designType,
        notes: value?.notes,
        showMap: value?.showMap,
        roomTable: value?.roomTable,
        aiDescriptionsWordCount: value?.aiDescriptionsWordCount
    };
};

const ExposeService = () => {
    const classes = useStyles();
    const [flag, setFlag] = useState(true);
    const dispatch = useAppDispatch();
    const router = useRouter();
    const realEstateId = router.query["re"] as string;
    const workspace = useReduxSelector(getRealEstateWorkspace);
    const designType = useReduxSelector(getDesignType);
    const logoSrc = workspace?.extras?.use?.forExposeAssistant ? workspace?.extras?.logo?.src ?? "/logo.png" : "/logo.png";
    const realEstate = useReduxSelector(getRealEstate);
    const buyerRate = realEstate?.provisionRate?.buyerCommission ?? realEstate.workspace?.agentSettings?.provisionRate?.buyerCommission ?? 2.49;
    const sellerRate = realEstate?.provisionRate?.sellerCommission ?? realEstate.workspace?.agentSettings?.provisionRate?.sellerCommission ?? 2.49;
    const user = useReduxSelector(getUser);
    const [open, setOpen] = useState(false);
    const [coord, setCoord] = useState<{ lng: number; lat: number } | undefined>(undefined);
    const mapSrc = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/pin-s+555555(${coord?.lng},${coord?.lat})/${coord?.lng},${coord?.lat},14,0/500x300@2x?access_token=${MAPBOX_ACCESS_TOKEN}`;

    const dataOverview = useMemo(
        () =>
            [
                { key: "Objekt-Nummer", val: (realEstate as any)._id ?? (realEstate as any).id },
                { key: "Objektart", val: realEstate.category ? categoryLabel[realEstate.category] : undefined },
                { key: "Objekttyp", val: realEstate.construction ? constructionLabel[realEstate.construction] : undefined },
                { key: "Hausgeld", val: realEstate.houseCharges },
                { key: "Baujahr", val: realEstate.constructionYear ? new Date(realEstate.constructionYear).getFullYear() : undefined },
                { key: "Nutzungsart", val: realEstate.use?.replace("_", " ").toLowerCase() },
                { key: "Straße", val: realEstate.address?.street },
                { key: "Hausnummer", val: realEstate.address?.house_number },
                { key: "PLZ", val: realEstate.address?.zip },
                { key: "Ort", val: realEstate.address?.town },
                { key: "Wohnfläche", val: realEstate?.livingArea ? "ca. " + realEstate.livingArea + " m²" : undefined },
                { key: "Grundstücksfläche", val: realEstate?.plotArea ? "ca. " + realEstate.plotArea + " m²" : undefined },
                { key: "Gewerbefläche", val: realEstate?.commercialSpace ? "ca. " + realEstate?.commercialSpace + " m²" : undefined },
                { key: "Anzahl Zimmer", val: realEstate?.rooms },
                { key: "Badezimmer", val: realEstate?.bathrooms },
                { key: "Anzahl Einheiten im Haus", val: realEstate?.residentialUnits },
                { key: "Etagenzahl", val: realEstate?.floorNumber },
                { key: "Balkon", val: realEstate.extras?.balcony ? "Ja" : undefined },
                { key: "Terrasse", val: realEstate.extras?.terrace ? "Ja" : undefined },
                { key: "Garten", val: realEstate.extras?.garden ? "Ja" : undefined },
                { key: "Wintergarten", val: realEstate.extras?.winterGarden ? "Ja" : undefined },
                { key: "Pool", val: realEstate.extras?.pool ? "Ja" : undefined },
                { key: "Sauna", val: realEstate.extras?.sauna ? "Ja" : undefined },
                { key: "Fahrstuhl", val: realEstate?.extras?.elevator ? "Ja" : undefined },
                { key: "Abstellraum", val: realEstate?.extras?.storageRoom ? "Ja" : undefined },
                { key: "Nahegelegene Sehenswürdigkeiten", val: realEstate?.extras?.nearbyAttractions ? "Ja" : undefined },
                { key: "Keller", val: realEstate.basement ? "Ja" : undefined },
                { key: "Garage", val: realEstate.garages ? "Ja" : undefined },
                { key: "Stellplatz", val: realEstate.outdoorParkingSpace ? "Ja" : undefined },
                { key: "Aufzug", val: realEstate.extras?.elevator ? "Ja" : undefined },
                { key: "Zustand", val: conditionRealEstate[realEstate?.condition] ?? undefined },
                {
                    key: "Energiesausweis",
                    val: (realEstate?.energyInfo?.certificate && getEnergyData(realEstate?.energyInfo?.certificate?.certificateType)) ?? undefined
                },
                {
                    key: "Heizungsart",
                    val: heating[realEstate?.equipment?.heating] ?? undefined
                },
                {
                    key: "Energieträger",
                    val: (realEstate?.energyInfo?.certificate && energyType[realEstate?.energyInfo?.certificate["energySourceEnev2014"]]) ?? undefined
                },
                {
                    key: "Erstellungsdatum",
                    val:
                        (realEstate?.energyInfo?.certificate && realEstate?.energyInfo?.certificate?.creationDate === "BEFORE_01_MAY_2014"
                            ? "Vor dem 1. Mai 2014"
                            : "Am/nach dem 1. Mai 2014") ?? undefined
                },
                { key: "Ausstattung", val: services[realEstate?.equipment?.value] ?? undefined },
                {
                    key: "Endenergieverbrauch",
                    val:
                        realEstate?.energyInfo?.certificate && realEstate?.energyInfo?.certificate[getEnergyConsumption(realEstate)]
                            ? realEstate?.energyInfo?.certificate[getEnergyConsumption(realEstate)] + " kWh / m²a"
                            : undefined
                },
                {
                    key: "Kaufpreis",

                    val:
                        realEstate.askingPrice.priceAssistant || realEstate.askingPrice.evaluation
                            ? CurrencyShort(realEstate.askingPrice.priceAssistant ?? realEstate.askingPrice.evaluation) + getCurrencySymbol(realEstate)
                            : "auf Anfrage"
                },
                getProvisionData(realEstate),
                getProvisionBuyer(realEstate),
                getProvisionSeller(realEstate)
            ].filter(item => !!item?.val),

        [buyerRate, realEstate, sellerRate]
    );

    const generalData = GeneralDataTable(realEstate).filter(item => !!item?.val);
    const energyData = EnergyDataTable(realEstate).filter(item => !!item?.val);
    const updatedGeneralData = useMemo(() => reArrange(generalData, realEstate, GeneralDataTable), [generalData, realEstate]);
    const updatedEnergyData = useMemo(() => reArrange(energyData, realEstate, EnergyDataTable), [energyData, realEstate]);
    const roomTableData = useMemo(() => reArrangeRoomData(realEstate), [realEstate]);
    const roomTableDataClean = useMemo(() => reArrangeRoomDataClean(realEstate), [realEstate]);

    const {
        control,
        watch,
        handleSave,
        setValue,
        getValues,
        formState: { errors }
    } = useRealEstateForm(realEstateId, selector, setter);
    const { dirtyFields } = useFormState({ control });

    const noShowKeys = ["Absolute Provision", "Verkäuferprovision", "Käuferprovision"];
    const dataOverviewUpdatedByUser = useMemo(() => {
        const data = [];
        const exposeFields = realEstate?.exposeFields;
        if (exposeFields) {
            Object.entries(dataOverview).map(entry => {
                if (exposeFields[entry[1]["key"]] && !noShowKeys.includes(entry[1]["key"])) {
                    let entryObj = {
                        key: entry[1]["key"],
                        val: RealEstateData(realEstate).find(item => item && item["key"] === entry[1]["key"])["val"]
                    };
                    data.push(entryObj);
                } else if (exposeFields[entry[1]["key"]] && noShowKeys.includes(entry[1]["key"])) {
                    switch (entry[1]["key"]) {
                        case "Absolute Provision":
                            return data.push(getProvisionData(realEstate));
                        case "Verkäuferprovision":
                            return data.push(getProvisionSeller(realEstate));
                        case "Käuferprovision":
                            return data.push(getProvisionBuyer(realEstate));
                    }
                }
            });
            return data.filter(item => !!item?.val);
        } else return dataOverview;
    }, [realEstate]);

    const handleClick = async () => {
        // @ts-expect-error
        const exposeFields = getValues("exposeFields");
        const extras = getValues("extras");
        const roomTable = getValues("roomTable");
        await dispatch(
            $updateExtras({
                workspaceId: workspace?._id,
                value: extras?.logoSize,
                caption: extras?.showCaption,
                logoPosition: extras?.logoPosition,
                opacity: extras?.logoOpacity
            })
        );
        await dispatch($updateRealEstateRoomsData({ id: realEstateId, value: roomTable }));
        await dispatch($updateExposeFields({ id: realEstateId, value: exposeFields }));
        await handleSave(dirtyFields);
        setFlag(!flag);
    };

    useEffect(() => {
        if (realEstateId && !coord)
            (async () => {
                const valResponse = await dispatch($getRealEstateValuationDocument({ realEstateId }));
                const valDoc = valResponse["payload"]["valuation"] as ValuationDocument;
                const lat =
                    valDoc?.valuationType === "priceHubble"
                        ? (valDoc?.request as PriceHubbleValuation)?.property?.location?.coordinates?.latitude
                        : (valDoc?.request as Valuation)?.address_geocoded?.lat;
                const lng =
                    valDoc?.valuationType === "priceHubble"
                        ? (valDoc?.request as PriceHubbleValuation)?.property?.location?.coordinates?.longitude
                        : (valDoc?.request as Valuation)?.address_geocoded?.lng;
                if (valDoc) setCoord({ lat: lat, lng: lng });
            })();
    }, [realEstateId]);

    const doc =
        designType === "clean" ? (
            <OldDocument
                realEstate={realEstate}
                workspace={workspace}
                logoSrc={logoSrc}
                data={dataOverviewUpdatedByUser}
                user={user}
                mapSrc={mapSrc}
                roomTableDataClean={roomTableDataClean}
            />
        ) : (
            <NewDocument
                realEstate={realEstate}
                workspace={workspace}
                logoSrc={logoSrc}
                generalData={updatedGeneralData}
                energyData={updatedEnergyData}
                user={user}
                mapSrc={mapSrc}
                roomTableData={roomTableData}
            />
        );

    const handleUpload = async () => {
        setOpen(true);
        if (realEstateId) {
            const exposeDocument = realEstate?.expose?.exposeDocument;
            const file = await pdf(doc).toBlob();
            if (exposeDocument) {
                const response = await dispatch($removeAsset({ id: realEstateId, details: exposeDocument, isSecure: false }));
                if (response["payload"]["status"] === 200) {
                    await dispatch($uploadAsset({ id: realEstateId, file: file, fileName: "expose.pdf", isSecure: false }));
                    setOpen(false);
                }
            } else {
                await dispatch($uploadAsset({ id: realEstateId, file: file, fileName: "expose.pdf", isSecure: false }));
                setOpen(false);
            }
        }
    };

    return (
        <Fragment>
            <Grid container justifyContent={"center"} style={{ width: "100%" }}>
                <Grid container justifyContent={"center"} item xs={6} style={{ marginRight: -32 }}>
                    <StickyHeader>
                        <Grid item xs={6} style={{ paddingLeft: 0 }}>
                            <Box display="flex" justifyContent="flex-start" alignItems="center">
                                <img
                                    alt={"/logo.png"}
                                    style={{ verticalAlign: "middle", borderStyle: "none", marginTop: 16, width: 120 }}
                                    src={"/logo.png"}
                                />
                                <Typography style={{ marginLeft: 8, marginTop: 14, width: 300 }}> Exposé</Typography>
                            </Box>
                        </Grid>
                        <Grid item container direction="row" justifyContent="flex-end" xs={6} style={{ display: "flex", paddingTop: 10, paddingRight: 0 }}>
                            <Grid item xs={12} justifyContent="flex-end" style={{ display: "flex", paddingRight: 0 }}>
                                <LoadingButton variant="contained" color="primary" style={{ marginRight: 10, minWidth: 205 }} onClick={handleClick}>
                                    Änderungen speichern
                                </LoadingButton>
                                <Button variant="contained" color="primary" style={{ minWidth: 150 }} onClick={handleUpload}>
                                    PDF hochladen
                                </Button>
                                <LoadingDialog open={open} onClose={() => setOpen(false)} />
                            </Grid>
                        </Grid>
                    </StickyHeader>
                    <Grid item xs={12} style={{ marginTop: 30 }}>
                        {cardTypes.map(card => (
                            <Grid item xs={12} key={card} className={classes.itemGrid}>
                                <CardLoader control={control} cardType={card} watch={watch} setValue={setValue} getValues={getValues} errors={errors} />
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
                <Grid container item xs={6} alignItems={"stretch"}>
                    <PdfPreview flag={flag} doc={doc} />
                </Grid>
            </Grid>
        </Fragment>
    );
};

export default ExposeService;
