import { replace } from "lodash";
import numeral from "numeral";
require("numeral/locales/de");
import { format, formatDistanceToNow } from "date-fns";
import { Address } from "@onpreo/database/src";

// ----------------------------------------------------------------------

numeral.locale("de");
numeral.localeData("de").delimiters.thousands = ".";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: {
                    key: "Hello"
                }
            },
            de: {
                translation: {
                    key: "Hallo"
                }
            }
        },
        fallbackLng: "de",
        detection: {
            order: ["navigator", "cookie", "htmlTag"],
            caches: ["cookie", "localStorage"]
        },
        interpolation: {
            escapeValue: false
        }
    });

export function getSeparators() {
    const locale = i18n.language;
    const formatter = new Intl.NumberFormat(locale, { useGrouping: true });
    const parts = formatter.formatToParts(123456);
    let thousandSeparator = "";
    let decimalSeparator = "";

    for (let part of parts) {
        if (part.type === "group") {
            thousandSeparator = part.value;
        } else if (part.type === "decimal") {
            decimalSeparator = part.value;
        }
    }

    // special case for Swiss local to use ' instead of ´
    if (locale === "de-CH") {
        thousandSeparator = "'";
    }
    // make sure to set a default thousand separator if none is found
    thousandSeparator = thousandSeparator || ".";
    decimalSeparator = decimalSeparator || ",";

    return { thousandSeparator, decimalSeparator };
}

export function currencyCodeToSymbol(currencyCode, locale = "en") {
    const formatter = new Intl.NumberFormat(locale, {
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: 0
    });

    // formatting a symbolic number to extract the currency format
    const formatted = formatter.format(0);

    // delete numeric and extra characters to get only the currency symbol
    const symbol = formatted.replace(/[\d.,\s]/g, "").trim();

    return " " + symbol;
}

export function CurrencyShort(number) {
    const locale = i18n.language;
    const formatter = new Intl.NumberFormat(locale, {
        style: "decimal",
        useGrouping: true,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    let formattedNumber = formatter.format(number);

    if (locale === "de-CH") {
        const parts = formatter.formatToParts(123456);
        let incorrectSeparator = "";

        for (let part of parts) {
            if (part.type === "group") {
                incorrectSeparator = part.value;
                break;
            }
        }
        const correctSeparator = "'";
        formattedNumber = formattedNumber.replace(new RegExp(incorrectSeparator, "g"), correctSeparator);
    }

    return formattedNumber;
}

export function Currency(number) {
    // return numeral(number).format(Number.isInteger(number) ? "0,0" : "0.0.00");
    return numeral(number).format("0,0.00");
}

export function Percent(number) {
    return numeral(number / 100).format("0.0%");
}

export function formatNumber(number) {
    return numeral(number).format();
}

export function ShortenNumber(number) {
    return replace(numeral(number).format("0.00a"), ".00", "");
}

export function Data(number) {
    return numeral(number).format("0.0 b");
}

export function formatDate(date, isUnixTimestamp = false) {
    return format(new Date(date * (isUnixTimestamp ? 1000 : 1)), "dd MMMM yyyy");
}

export function DateTime(date) {
    return format(new Date(date), "dd MMM yyyy HH:mm");
}

export function DateTimeSuffix(date) {
    return format(new Date(date), "dd/MM/yyyy hh:mm p");
}

export function ToNow(date) {
    return formatDistanceToNow(new Date(date), {
        addSuffix: true
    });
}

export function parseAddress(address: Address) {
    console.log(address);
    return `${address.street} ${address.house_number}, ${address.zip} ${address.town}, ${address.nation}`;
}

export function parseLocaleNumber(stringNumber) {
    const { thousandSeparator, decimalSeparator } = getSeparators();
    const cleanNumber = stringNumber
        .replace(new RegExp(`\\${thousandSeparator}`, "g"), "") // delete thousand separator
        .replace(new RegExp(`\\${decimalSeparator}`, "g"), "."); // replace decimal separator with dot, to get a valid number
    return parseFloat(cleanNumber);
}

export const searchParams = () => {
    if (typeof window !== "undefined") {
        return window.location.search;
        // return new Proxy(new URLSearchParams(window.location.search), {
        //     get: (searchParams, prop) => searchParams.get(prop as string)
        // });
    } else return undefined;
};

export function formatDateInfo(date) {
    if (date) return format(new Date(date), "dd.MM.yyyy");
}

export function replaceSpaces(str: string) {
    return str?.replace(/\s/g, "");
}

export function replaceUmlaut(str: string) {
    let replacedString = str;
    for (var i = 0; i < replacedString.length; i++) {
        if (str.charCodeAt(i) === 776) {
            replacedString = replacedString.replace(str.charAt(i), "e");
        } else if (str.charCodeAt(i) === 223) {
            replacedString = replacedString.replace(str.charAt(i), "ss");
        } else if (str.charCodeAt(i) === 769) {
            replacedString = replacedString.replace(str.charAt(i), "e");
        }
    }
    return replacedString;
}

export const arrayRange = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

export default {
    Currency: CurrencyShort,
    Percent,
    formatNumber,
    ShortenNumber,
    Data,
    formatDate,
    DateTime,
    DateTimeSuffix,
    ToNow,
    parseLocaleNumber,
    replaceSpaces,
    replaceUmlaut,
    arrayRange
};
