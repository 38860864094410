import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React, { FC } from "react";
import { PageLayoutProps } from "./page-layout";
import { SmallText } from "./text";

const styles = StyleSheet.create({
    footer: {
        position: "absolute",
        bottom: -48,
        // bottom: 0,
        color: "grey",
        // left: 0,
        // right: 0,
        marginVertical: 8,
        // backgroundColor: "red",
        // border: "2px solid blue",
        height: 54
    },
    smallText: {
        display: "flex",
        fontSize: 10,
        width: "auto",
        textAlign: "center"
    },
    bold: {
        color: "black"
    }
});

export const PageFooter: FC<PageLayoutProps> = ({ realEstate, workspace, user }) => {
    const responsibleUser = user;
    const publication = realEstate.workspace?.publication;
    const companyInfo = realEstate.workspace?.companyInfo;

    const name = responsibleUser?.meta?.name ?? publication?.firstName + " " + publication?.lastName;
    const phone = responsibleUser?.meta?.phone ?? publication?.phone;
    const email = responsibleUser?.email ?? publication?.email;
    const website = companyInfo?.website;
    const designType = realEstate?.expose?.designType;
    const companyName = companyInfo?.company;
    const address = responsibleUser?.meta?.address || workspace?.companyInfo?.address;
    const fillerString = " " + "|" + " ";

    return (
        <View style={{ ...styles.footer, paddingTop: designType === "modern" ? 20 : 0 }} fixed>
            {designType === "modern" ? (
                <>
                    <SmallText fixed style={{ ...styles.bold, fontFamily: "Poppins", fontWeight: "bold" }}>
                        {" "}
                        {companyName +
                            fillerString +
                            address?.street +
                            " " +
                            address?.house_number +
                            fillerString +
                            address?.zip +
                            " " +
                            address?.town +
                            fillerString +
                            "Tel.:" +
                            " " +
                            phone +
                            (website ? fillerString + website : "")}{" "}
                    </SmallText>
                </>
            ) : (
                <View style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                    <SmallText fixed style={{ ...styles.smallText, ...styles.bold }}>
                        {name}
                    </SmallText>
                    <SmallText fixed>{phone ? "Tel.: " + phone : ""}</SmallText>
                    <SmallText fixed>{email ? "E-Mail.: " + email : ""}</SmallText>
                    <SmallText fixed>{website ?? ""}</SmallText>
                </View>
            )}
        </View>
    );
};
