import { View } from "@react-pdf/renderer";
import { ExposePage } from "@onpreo/pdf-components";
import { BigImages } from "./impressions";

const FloorPlan = ({ ...props }) => {
    const floorPlanPhotos = props.realEstate?.expose?.propertyPhotos?.floorPlan;
    const showCaption = props.realEstate?.workspace?.extras?.showCaption;

    return (
        <ExposePage {...props}>
            <View wrap>{floorPlanPhotos?.length > 0 && <BigImages imgs={floorPlanPhotos} showCaption={showCaption} isContained={true} />}</View>
        </ExposePage>
    );
};

export default FloorPlan;
