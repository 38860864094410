import { DataTableCell, Table, TableBody, ExposePage, SubTitle, Typo, PdfMapImage } from "@onpreo/pdf-components";
import { CurrencyShort, formatDateInfo } from "../../utils/formatters";
import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import { getCurrencySymbol } from "../real-estate-properties";

const styles = StyleSheet.create({
    text: {
        color: "black",
        paddingTop: 10,
        fontSize: 12,
        fontFamily: "Poppins"
    },
    title: {
        paddingTop: 20,
        paddingBottom: 4,
        color: "black",
        fontFamily: "Poppins",
        fontWeight: "semibold"
    },
    tableCell: {
        fontSize: 9,
        color: "#383838",
        fontFamily: "Poppins"
    },
    note: { padding: 15, paddingLeft: 0, color: "black", fontSize: 9, fontFamily: "Poppins", fontWeight: "semibold" },
    subHeader: {
        padding: 20,
        paddingLeft: 0,
        color: "black",
        fontSize: 18,
        fontFamily: "Poppins",
        fontWeight: "semibold",
        paddingTop: 0,
        paddingBottom: 0
    },
    price: { padding: 20, paddingLeft: 0, fontSize: 15, fontFamily: "Poppins", fontWeight: "semibold" }
});

const DescriptionModern = props => {
    const readyForOccupation = props.realEstate?.readyForOccupation
        ? " | " + "Bezugsfrei ab dem" + " " + formatDateInfo(props.realEstate?.readyForOccupation)
        : "";

    const breakCheckArray = [
        props.realEstate?.expose?.notes?.furnishing,
        props.realEstate.expose?.notes?.description,
        props.realEstate.expose?.notes?.location
    ];

    const containsUndefined = breakCheckArray.filter(item => item === undefined);

    return (
        <ExposePage {...props} type="modern">
            <View style={{ backgroundColor: "#F0F1F1", width: "80vw", padding: 30, paddingBottom: 0 }}>
                <Typo style={styles.subHeader}> Bausubstanz & Energieausweis </Typo>
                <Typo style={{ ...styles.price, color: props?.workspace?.publication?.primary ?? "#0071E3" }}>
                    {" "}
                    {(props.realEstate?.askingPrice?.priceAssistant || props.realEstate?.askingPrice?.evaluation
                        ? CurrencyShort(props.realEstate?.askingPrice?.priceAssistant ?? props.realEstate?.askingPrice?.evaluation) +
                          getCurrencySymbol(props.realEstate)
                        : "auf Anfrage") + readyForOccupation}{" "}
                </Typo>
                <Table data={props.data}>
                    <TableBody renderTopBorder={false}>
                        <DataTableCell weighting={0.25} getContent={r => r.keyLeft} style={styles.tableCell} />
                        <DataTableCell weighting={0.25} getContent={r => r.valLeft} style={styles.tableCell} />
                        <DataTableCell weighting={0.25} getContent={r => r.keyRight} style={styles.tableCell} />
                        <DataTableCell weighting={0.25} getContent={r => r.valRight} style={styles.tableCell} />
                    </TableBody>
                </Table>
                <Typo style={styles.note}> ALLE ANGABEN OHNE GEWÄHR UND HAFTUNG </Typo>
            </View>
            {props.realEstate.expose?.notes?.furnishing && (
                <View>
                    <SubTitle style={styles.title}>Ausstattung</SubTitle>
                    <Typo style={styles.text}>{props.realEstate?.expose?.notes?.furnishing}</Typo>
                </View>
            )}
            {props.realEstate.expose?.notes?.description && (
                <View>
                    <SubTitle style={styles.title}>Objektbeschreibung</SubTitle>
                    <Typo style={styles.text}>{props.realEstate?.expose?.notes?.description}</Typo>
                </View>
            )}
            {props.realEstate.expose?.notes?.location && (
                <View>
                    <SubTitle style={styles.title}>Lage</SubTitle>
                    <Typo style={styles.text}>{props.realEstate.expose?.notes?.location}</Typo>
                </View>
            )}
            {props?.realEstate?.expose?.showMap && props?.mapSrc && (
                <PdfMapImage
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        maxHeight: 200,
                        marginLeft: 0,
                        width: "80vw",
                        maxWidth: "80vw",
                        objectFit: "cover",
                        marginTop: 30,
                        marginBottom: 20
                    }}
                    src={props.mapSrc}
                />
            )}
            {props.realEstate.expose?.notes?.other && (
                <View wrap={false}>
                    <SubTitle style={styles.title}>Zusätzliche Informationen</SubTitle>
                    <Typo style={styles.text}>{props.realEstate.expose?.notes?.other}</Typo>
                </View>
            )}
        </ExposePage>
    );
};

export default DescriptionModern;

//break={props.realEstate.expose?.notes?.furnishing && props.realEstate.expose?.notes?.description ? true : false}
//break={containsUndefined.length > 2 ? false : true}
