import { RealEstate } from "@onpreo/database/src/definitions/real-estate";
import { Currency, CurrencyShort } from "../utils/formatters";
import { categoryLabel, conditionRealEstate, constructionLabel, energyType, heating, services } from "./pdf-pages/util";

export function getProvisionData(realEstate: RealEstate) {
    const hasRealEstateFixedProvision = realEstate?.hasFixedProvision;
    const hasFixedProvision = realEstate?.workspace?.agentSettings?.hasFixedProvision;
    const currency = realEstate?.askingPrice?.currency;
    const symbol = currency === "EUR" ? " €" : " CHF";
    if (hasRealEstateFixedProvision) return { key: "Absolute Provision", val: CurrencyShort(realEstate?.fixedProvision) + symbol };
    else if (
        !hasRealEstateFixedProvision &&
        realEstate?.provisionRate &&
        (!realEstate?.provisionRate?.buyerCommission || !realEstate?.provisionRate?.sellerCommission) &&
        hasFixedProvision
    )
        return { key: "Absolute Provision", val: CurrencyShort(realEstate?.workspace?.agentSettings?.fixedProvision) + symbol };
    else return;
}

export function getProvisionBuyer(realEstate: RealEstate) {
    const hasRealEstateFixedProvision = realEstate?.hasFixedProvision;
    const hasFixedProvision = realEstate?.workspace?.agentSettings?.hasFixedProvision;
    const realEstateRate = realEstate.provisionRate?.buyerCommission;
    const workspaceRate = realEstate.workspace?.agentSettings?.provisionRate?.buyerCommission;
    const actualBuyerProvision = Number(realEstateRate ?? workspaceRate ?? 2.49);
    if (!hasRealEstateFixedProvision && !hasFixedProvision) return { key: "Käuferprovision", val: Currency(actualBuyerProvision) + " % (inkl. MwSt.)" };
    else if (!hasRealEstateFixedProvision && realEstate?.provisionRate && realEstate?.provisionRate?.buyerCommission && hasFixedProvision)
        return { key: "Käuferprovision", val: Currency(actualBuyerProvision) + " % (inkl. MwSt.)" };
    else return;
}

export function getProvisionSeller(realEstate: RealEstate) {
    const hasRealEstateFixedProvision = realEstate?.hasFixedProvision;
    const hasFixedProvision = realEstate?.workspace?.agentSettings?.hasFixedProvision;
    const realEstateRate = realEstate.provisionRate?.sellerCommission;
    const workspaceRate = realEstate.workspace?.agentSettings?.provisionRate?.sellerCommission;
    const actualSellerProvision = Number(realEstateRate ?? workspaceRate ?? 2.49);
    if (!hasRealEstateFixedProvision && !hasFixedProvision) return { key: "Verkäuferprovision", val: Currency(actualSellerProvision) + " % (inkl. MwSt.)" };
    else if (!hasRealEstateFixedProvision && realEstate?.provisionRate && realEstate?.provisionRate?.sellerCommission && hasFixedProvision)
        return { key: "Verkäuferprovision", val: Currency(actualSellerProvision) + " % (inkl. MwSt.)" };
    else return;
}

export function getEnergyData(certificateType: any) {
    switch (certificateType) {
        case "ENERGY_REQUIRED":
            return "Bedarfsausweis";
        case "ENERGY_CONSUMPTION":
            return "Verbrauchsausweis";
        default:
            return;
    }
}

export function getEnergyConsumption(realEstate: RealEstate) {
    const certificateType = realEstate?.energyInfo?.certificate?.certificateType;
    const buildingType = realEstate?.energyInfo?.certificate?.buildingType;
    const creationDate = realEstate?.energyInfo?.certificate?.creationDate;
    switch (certificateType) {
        case "ENERGY_REQUIRED":
            if (creationDate === "BEFORE_01_MAY_2014") return "thermalCharacteristic";
            else if (creationDate === "FROM_01_MAY_2014" && buildingType === "COMMERCIAL") return "thermalCharacteristicElectricity";
            else return "thermalCharacteristic";
        case "ENERGY_CONSUMPTION":
            if (creationDate === "BEFORE_01_MAY_2014" && buildingType === "COMMERCIAL") return "electricityConsumption";
            else if (creationDate === "BEFORE_01_MAY_2014" && buildingType === "NON_COMMERCIAL") return "thermalCharacteristic";
            else if (creationDate === "FROM_01_MAY_2014" && buildingType === "COMMERCIAL") return "energyConsumptionElectricity";
            else if (creationDate === "FROM_01_MAY_2014" && buildingType === "NON_COMMERCIAL") return "thermalCharacteristic";
        default:
            return;
    }
}

export function getCurrencySymbol(realEstate: RealEstate) {
    const currencySymbol = realEstate.askingPrice?.currency === "CHF" ? " CHF" : " €";
    return currencySymbol;
}

export const RealEstateData = (realEstate: RealEstate) => [
    { key: "Objekt-Nummer", val: realEstate?.customId || ((realEstate as any)?._id ?? (realEstate as any).id) },
    { key: "Objektart", val: realEstate?.category ? categoryLabel?.[realEstate?.category] : undefined },
    { key: "Objekttyp", val: realEstate?.construction ? constructionLabel?.[realEstate?.construction] : undefined },
    { key: "Hausgeld", val: realEstate?.houseCharges },
    { key: "Baujahr", val: realEstate?.constructionYear ? new Date(realEstate?.constructionYear).getFullYear() : undefined },
    { key: "Nutzungsart", val: realEstate?.use?.replace("_", " ").toLowerCase() },
    { key: "Straße", val: realEstate?.address?.street },
    { key: "Hausnummer", val: realEstate?.address?.house_number },
    { key: "PLZ", val: realEstate?.address?.zip },
    { key: "Ort", val: realEstate?.address?.town },
    { key: "Wohnfläche", val: "ca. " + realEstate?.livingArea + " m²" },
    { key: "Grundstücksfläche", val: "ca. " + realEstate?.plotArea + " m²" },
    { key: "Gewerbefläche", val: realEstate?.commercialSpace ? "ca. " + realEstate?.commercialSpace + " m²" : undefined },
    { key: "Anzahl Zimmer", val: realEstate?.rooms },
    { key: "Badezimmer", val: realEstate?.bathrooms },
    { key: "Anzahl Einheiten im Haus", val: realEstate?.residentialUnits },
    { key: "Etagenzahl", val: realEstate?.floorNumber },
    { key: "Balkon", val: realEstate?.extras?.balcony ? "Ja" : undefined },
    { key: "Terrasse", val: realEstate?.extras?.terrace ? "Ja" : undefined },
    { key: "Garten", val: realEstate?.extras?.garden ? "Ja" : undefined },
    { key: "Wintergarten", val: realEstate?.extras?.winterGarden ? "Ja" : undefined },
    { key: "Pool", val: realEstate?.extras?.pool ? "Ja" : undefined },
    { key: "Sauna", val: realEstate?.extras?.sauna ? "Ja" : undefined },
    { key: "Fahrstuhl", val: realEstate?.extras?.elevator ? "Ja" : undefined },
    { key: "Abstellraum", val: realEstate?.extras?.storageRoom ? "Ja" : undefined },
    { key: "Nahegelegene Sehenswürdigkeiten", val: realEstate?.extras?.nearbyAttractions ? "Ja" : undefined },
    { key: "Keller", val: realEstate?.basement ? "Ja" : undefined },
    { key: "Garage", val: realEstate?.garages ? "Ja" : undefined },
    { key: "Stellplatz", val: realEstate?.outdoorParkingSpace ? "Ja" : undefined },
    { key: "Zustand", val: conditionRealEstate[realEstate?.condition] ?? undefined },
    { key: "Energiesausweis", val: realEstate?.energyInfo?.certificate && getEnergyData(realEstate?.energyInfo?.certificate?.certificateType) },
    {
        key: "Heizungsart",
        val: heating[realEstate?.equipment?.heating] ?? undefined
    },
    {
        key: "Energieträger",
        val: (realEstate?.energyInfo?.certificate && energyType[realEstate?.energyInfo?.certificate["energySourceEnev2014"]]) ?? undefined
    },
    {
        key: "Erstellungsdatum",
        val:
            (realEstate?.energyInfo?.certificate && realEstate?.energyInfo?.certificate?.creationDate === "BEFORE_01_MAY_2014"
                ? "Vor dem 1. Mai 2014"
                : "Am/nach dem 1. Mai 2014") ?? undefined
    },
    { key: "Ausstattung", val: services[realEstate?.equipment?.value] ?? undefined },
    {
        key: "Endenergieverbrauch",
        val:
            realEstate?.energyInfo?.certificate && realEstate?.energyInfo?.certificate[getEnergyConsumption(realEstate)]
                ? +realEstate?.energyInfo?.certificate[getEnergyConsumption(realEstate)] + " kWh / m²a"
                : undefined
    },
    {
        key: "Kaufpreis",
        val:
            realEstate.askingPrice?.priceAssistant || realEstate.askingPrice?.evaluation
                ? CurrencyShort(realEstate.askingPrice?.priceAssistant ?? realEstate.askingPrice?.evaluation) + getCurrencySymbol(realEstate)
                : "auf Anfrage"
    },
    getProvisionData(realEstate),
    getProvisionBuyer(realEstate),
    getProvisionSeller(realEstate)
];

export const GeneralDataTable = (realEstate: RealEstate) => [
    {
        key: "Objektart",
        val: realEstate?.category ? categoryLabel?.[realEstate?.category] : undefined
    },
    { key: "Objekttyp", val: realEstate?.construction ? constructionLabel?.[realEstate?.construction] : undefined },
    {
        key: "Wohnfläche",
        val: realEstate?.livingArea ? "ca. " + realEstate?.livingArea + " m²" : undefined
    },
    { key: "Grundstücksfläche", val: realEstate?.plotArea ? "ca. " + realEstate?.plotArea + " m²" : undefined },
    { key: "Anzahl Zimmer", val: realEstate?.rooms },
    { key: "Badezimmer", val: realEstate?.bathrooms },
    { key: "Etagenzahl", val: realEstate?.floorNumber },
    { key: "Nutzungsart", val: realEstate?.use?.replace("_", " ").toLowerCase() },
    {
        key: "Garage",
        val: realEstate?.garages ? "Ja" : undefined
    },
    { key: "Stellplatz", val: realEstate?.outdoorParkingSpace ? "Ja" : undefined }
];

export const EnergyDataTable = (realEstate: RealEstate) => [
    {
        key: "Baujahr",
        val: realEstate?.constructionYear ? new Date(realEstate?.constructionYear).getFullYear() : undefined
    },
    {
        key: "Energiesausweis",
        val: (realEstate?.energyInfo?.certificate && getEnergyData(realEstate?.energyInfo?.certificate?.certificateType)) ?? undefined
    },
    {
        key: "Ausstattung",
        val: services[realEstate?.equipment?.value] ?? undefined
    },
    {
        key: "Endenergieverbrauch",
        val:
            realEstate?.energyInfo?.certificate && realEstate?.energyInfo?.certificate[getEnergyConsumption(realEstate)]
                ? realEstate?.energyInfo?.certificate[getEnergyConsumption(realEstate)] + " kWh / m²a"
                : undefined
    },
    {
        key: "Heizungsart",
        val: heating[realEstate?.equipment?.heating] ?? undefined
    },
    {
        key: "Energieträger",
        val: (realEstate?.energyInfo?.certificate && energyType[realEstate?.energyInfo?.certificate["energySourceEnev2014"]]) ?? undefined
    },
    {
        key: "Zustand",
        val: conditionRealEstate[realEstate?.condition] ?? undefined
    },
    {
        key: "Erstellungsdatum",
        val:
            (realEstate?.energyInfo?.certificate && realEstate?.energyInfo?.certificate?.creationDate === "BEFORE_01_MAY_2014"
                ? "Vor dem 1. Mai 2014"
                : "Am/nach dem 1. Mai 2014") ?? undefined
    }
];

export function reArrange(data, realEstate, tableType) {
    const finalData = [];
    const keys = data.map(item => {
        return item.key;
    });
    const requiredKeys = realEstate?.exposeFields ? keys.filter(key => realEstate?.exposeFields[key]) : keys;
    requiredKeys.forEach((item, index) => {
        if (index !== requiredKeys.length - 1 && index % 2 === 0) {
            let entryObj = {
                keyLeft: requiredKeys[index],
                valLeft: tableType(realEstate).find(item => item && item["key"] === requiredKeys[index])["val"],
                keyRight: requiredKeys[index + 1],
                valRight: tableType(realEstate).find(item => item && item["key"] === requiredKeys[index + 1])["val"]
            };
            finalData.push(entryObj);
        } else if (requiredKeys.length % 2 !== 0 && index === requiredKeys.length - 1) {
            let entryObj = {
                keyLeft: requiredKeys[index],
                valLeft: tableType(realEstate).find(item => item && item["key"] === requiredKeys[index])["val"]
            };
            finalData.push(entryObj);
        }
    });
    return finalData;
}

export function reArrangeRoomData(realEstate) {
    const finalData = [];
    if (realEstate?.expose?.roomTable?.roomData) {
        const keys = realEstate?.expose?.roomTable?.roomData.map(item => {
            return item.roomType;
        });

        keys.forEach((item, index) => {
            if (index !== keys.length - 1 && index % 2 === 0) {
                let entryObj = {
                    keyLeft: keys[index],
                    valLeft: `${realEstate?.expose?.roomTable?.roomData?.[index]?.roomArea} m²`,
                    keyRight: keys[index + 1],
                    valRight: `${realEstate?.expose?.roomTable?.roomData?.[index + 1]?.roomArea} m²`
                };
                finalData.push(entryObj);
            } else if (keys.length % 2 !== 0 && index === keys.length - 1) {
                let entryObj = {
                    keyLeft: keys[index],
                    valLeft: `${realEstate?.expose?.roomTable?.roomData?.[index]?.roomArea} m²`
                };
                finalData.push(entryObj);
            }
        });
    }

    return finalData;
}

export function reArrangeRoomDataClean(realEstate) {
    const finalData = [];
    if (realEstate?.expose?.roomTable?.roomData) {
        const keys = realEstate?.expose?.roomTable?.roomData.map(item => {
            return item.roomType;
        });

        keys.forEach((item, index) => {
            let entryObj = {
                key: keys[index],
                val: `${realEstate?.expose?.roomTable?.roomData?.[index]?.roomArea} m²`
            };
            finalData.push(entryObj);
        });
    }

    return finalData;
}
