import { View, StyleSheet } from "@react-pdf/renderer";
import { PdfImage, ExposePage, SubTitle, Title } from "@onpreo/pdf-components";
import { categoryLabel, getFontSize, getPositionBottom, getPositionTop } from "../pdf-pages/util";

const styles = StyleSheet.create({
    title: {
        textAlign: "left",
        color: "black",
        maxLines: 2,
        fontFamily: "Poppins",
        fontWeight: "medium"
    },
    subTitle: {
        textAlign: "left",
        padding: 0,
        color: "black",
        fontFamily: "Poppins",
        fontWeight: "normal"
    }
});

const CoverModern = ({ ...props }) => {
    const descriptionLength = props.realEstate?.description?.length ?? categoryLabel[props.realEstate?.category]?.length ?? 0;

    return (
        <ExposePage {...props}>
            <View style={{ marginTop: 18, marginBottom: 16, paddingBottom: 20 }}>
                <Title
                    style={{
                        ...styles.title,
                        fontSize: getFontSize(descriptionLength)
                    }}
                >
                    {props.realEstate?.description ?? categoryLabel[props.realEstate?.category]}
                </Title>
                <SubTitle style={{ ...styles.subTitle, textTransform: "uppercase" }}>
                    {props.realEstate?.address?.street + " " + props.realEstate?.address?.house_number}
                </SubTitle>
                <SubTitle style={styles.subTitle}>{props.realEstate?.address?.zip + " " + props.realEstate?.address?.town}</SubTitle>
            </View>
            <PdfImage
                style={{
                    display: "flex",
                    justifyContent: "center",
                    maxHeight: 354,
                    marginLeft: 0,
                    width: "80vw",
                    maxWidth: "80vw",
                    right: 0,
                    left: 0,
                    top: 0,
                    bottom: 0,
                    objectFit: "cover"
                }}
                src={props.realEstate.expose?.propertyPhotos?.title?.length > 0 ? props.realEstate.expose?.propertyPhotos?.title[0]?.img?.src : undefined}
            />
            <View style={{ backgroundColor: "black", height: 20, paddingLeft: 0, width: "50vw", marginLeft: 179 }} />
            <View
                style={{
                    backgroundColor: "black",
                    height: 20,
                    paddingLeft: 0,
                    width: "50vw",
                    position: "absolute",
                    top: getPositionTop(descriptionLength)
                }}
            />
            <View
                style={{
                    backgroundColor: "black",
                    height: 20,
                    paddingLeft: 0,
                    width: "50vw",
                    marginLeft: 179,
                    position: "absolute",
                    top: getPositionBottom(descriptionLength),
                    opacity: 0.5
                }}
            />
        </ExposePage>
    );
};

export default CoverModern;
