import { createStyles } from "@material-ui/core";

export const infoStyles = theme =>
    createStyles({
        statsCard: {
            width: "100%",
            minWidth: "100%",
            backgroundColor: "#0071E3",
            marginBottom: theme.spacing(2)
        },
        statsCardContent: {
            padding: theme.spacing(2)
        },
        typo: {
            color: "#fff",
            fontWeight: 500,
            fontSize: 13
        },
        smallTypo: {
            color: "#fff",
            fontWeight: 300,
            fontSize: 13
        },
        typoValue: {
            fontSize: 32,
            fontWeight: 600,
            marginRight: 5,
            [theme.breakpoints.down("lg")]: {
                fontSize: 28
            }
        },
        typoSub: {
            fontWeight: 500
        },
        textGrid: {
            paddingRight: 0,
            marginRight: theme.spacing(-2)
        },
        mapGrid: {
            padding: 0,
            height: 300,
            paddingBottom: 16
        },
        itemGrid: {
            padding: 0
        },
        priceFinderGrid: {
            margin: theme.spacing(1.5, 0, -1.35, 0)
        },
        buttonMargin: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(2),
            minWidth: 185
        },
        buttonWithNextIcons: {
            marginLeft: theme.spacing(0),
            minWidth: 120
        },
        iconButton: {
            marginLeft: theme.spacing(0),
            marginRight: theme.spacing(1)
        },

        ownerCard: {
            width: "100%",
            minHeight: 240,
            marginBottom: theme.spacing(2),
            position: "relative"
        },
        exposeInfoCard: {
            width: "100%",
            height: 150,
            marginBottom: theme.spacing(2)
        },
        infoCard: {
            width: "100%",
            paddingBottom: 0,
            marginBottom: theme.spacing(2),
            position: "relative"
        },
        cardContent: {
            padding: theme.spacing(2, 2, 1)
        },
        headerGrid: {
            margin: theme.spacing(0)
        },
        headerText: {
            color: "#32325D",
            fontWeight: 600,
            fontSize: 17
        },
        divider: {
            position: "absolute",
            width: "100%",
            marginTop: theme.spacing(7)
        },
        filledSelect: {
            width: "100%",
            minHeight: "46px",
            margin: theme.spacing(1, 0, 1, 0)
        },
        cardButton: {
            margin: "6px 0",
            width: 190,
            [theme.breakpoints.down("lg")]: {
                width: 160,
                fontSize: 11
            }
        },
        infoText: {
            color: "#32325D",
            fontSize: 14,
            lineHeight: 1.76,
            fontWeight: 400,
            [theme.breakpoints.down("lg")]: {
                fontSize: 13
            }
        },
        labelText: {
            color: "#32325D",
            fontWeight: 600,
            fontSize: 14,
            lineHeight: 1.76,
            [theme.breakpoints.down("lg")]: {
                fontSize: 13
            }
        },
        fieldTitleText: {
            marginTop: theme.spacing(1)
        },
        menu: {
            maxHeight: 300,
            maxWidth: 300
        },
        propertyImage: {
            width: "100%",
            height: 100,
            boxShadow: theme.shadows[2],
            objectFit: "cover",
            borderRadius: 5
        },
        imageGrid: {
            position: "relative",
            height: "100%"
        },
        switch: {
            marginRight: theme.spacing(0),
            marginTop: theme.spacing(0.5)
        },
        switchText: {
            color: "#525F7F",
            fontSize: 13,
            fontWeight: 400,
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(0),
            [theme.breakpoints.down("lg")]: {
                fontSize: 12
            }
        },
        switchGrid: {
            height: 50,
            border: "1px solid #E9ECEF"
        },
        switchServicesGrid: {
            marginTop: theme.spacing(2),
            height: 50,
            border: "1px solid #E9ECEF"
        },
        switchServicesContainer: {
            padding: theme.spacing(1)
        },
        switchServicesText: {
            color: "#525F7F",
            fontSize: 14,
            fontWeight: 400,
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(0),
            [theme.breakpoints.down("lg")]: {
                fontSize: 12
            }
        },
        switchContainer: {
            margin: theme.spacing(1)
        },
        documentsCardWrapper: {
            marginTop: theme.spacing(1)
        },
        infoIcon: {
            width: 16,
            height: 16,
            marginLeft: theme.spacing(0.5),
            marginBottom: theme.spacing(-0.25)
        },
        customTooltipWidth: {
            maxWidth: 400
        }
    });
