import { DataTableCell, ExposePage, Table, TableBody, Typo } from "@onpreo/pdf-components";
import { View, StyleSheet } from "@react-pdf/renderer";
import { reArrangeRoomData } from "../real-estate-properties";

const styles = StyleSheet.create({
    tableCell: {
        fontSize: 9,
        color: "#383838",
        fontFamily: "Poppins"
    },
    price: { padding: 20, paddingLeft: 0, fontSize: 15, fontFamily: "Poppins", fontWeight: "semibold" }
});

const RoomTable = props => {
    return (
        <ExposePage {...props}>
            <View style={{ backgroundColor: "#F0F1F1", width: "80vw", padding: 30 }}>
                <Typo style={{ ...styles.price, color: props?.workspace?.publication?.primary ?? "#0071E3" }}>Flächenaufteilung</Typo>
                <Table data={props.roomTableData}>
                    <TableBody renderTopBorder={false}>
                        <DataTableCell weighting={0.25} getContent={r => r.keyLeft} style={styles.tableCell} />
                        <DataTableCell weighting={0.25} getContent={r => r.valLeft} style={styles.tableCell} />
                        <DataTableCell weighting={0.25} getContent={r => r.keyRight} style={styles.tableCell} />
                        <DataTableCell weighting={0.25} getContent={r => r.valRight} style={styles.tableCell} />
                    </TableBody>
                </Table>
            </View>
        </ExposePage>
    );
};

export default RoomTable;
