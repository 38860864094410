import { RealEstate } from "@onpreo/database/src/definitions/real-estate";
import numeral from "numeral";
require("numeral/locales/de");
import { Address } from "@onpreo/database/src";

numeral.locale("de");
numeral.localeData("de").delimiters.thousands = ".";

export function CurrencyShort(number) {
    return numeral(number).format("0,0");
}

export function parseAddress(address: Address) {
    return `${address?.street} ${address?.house_number}, ${address?.zip} ${address?.town}, ${address?.nation}`;
}

export function getOtherOwnersEmail(realEstate: RealEstate): { key: string; val: string }[] {
    const otherOwnersEmail = [];
    if (!realEstate?.notary?.singleOwner?.isTrue) {
        realEstate?.notary?.singleOwner?.others.forEach((item, index) => {
            otherOwnersEmail.push({ key: `Miteigentümer ${index + 1}: Email`, val: item["email"] });
        });
        return otherOwnersEmail;
    } else return [{ key: undefined, val: undefined }];
}

export function getOtherOwnersName(realEstate: RealEstate): { key: string; val: string }[] {
    const otherOwnersName = [];
    if (!realEstate?.notary?.singleOwner?.isTrue) {
        realEstate?.notary?.singleOwner?.others.forEach((item, index) => {
            otherOwnersName.push({ key: `Miteigentümer ${index + 1}: Name`, val: item["name"] });
        });
        return otherOwnersName;
    } else return [{ key: undefined, val: undefined }];
}

export const generalData = (realEstate: RealEstate) => {
    return [
        {
            key: "Kundentyp",
            val: realEstate?.notary?.customerType ?? undefined
        },
        { key: "Alleiniger Eigentümer", val: realEstate?.notary?.singleOwner?.isTrue ? "Ja" : "Nein" ?? undefined },
        ...getOtherOwnersEmail(realEstate),
        ...getOtherOwnersName(realEstate)
    ].filter(item => !!item?.val);
};

export const personalData = (realEstate: RealEstate) => {
    return [
        {
            key: "Name",
            val: realEstate?.notary?.meta?.name ?? undefined
        },
        {
            key: "Geburtsname",
            val: realEstate?.notary?.birthName ?? undefined
        },
        {
            key: "Geburtstag",
            val: realEstate?.notary?.birthDate.toLocaleDateString() ?? undefined
        },
        {
            key: "Geburtsort",
            val: realEstate?.notary?.birthPlace ?? undefined
        },
        { key: "Staatsangehörigkeit", val: realEstate?.notary?.nationality ?? undefined }
    ].filter(item => !!item?.val);
};

export const addressData = (realEstate: RealEstate) => {
    return [
        {
            key: "Straße + HausNr.",
            val: realEstate?.notary?.meta?.address?.street + " " + realEstate?.notary?.meta?.address?.house_number ?? undefined
        },
        { key: "Postleitzahl", val: realEstate?.notary?.meta?.address?.zip ?? undefined },

        { key: "Ort", val: realEstate?.notary?.meta?.address?.town ?? undefined }
    ].filter(item => !!item?.val);
};

export const contactData = (realEstate: RealEstate) => {
    return [
        {
            key: "Telefon-Nummer",
            val: realEstate?.notary?.meta?.phone ?? undefined
        },
        { key: "E-Mail-Adresse", val: realEstate?.notary?.meta?.email ?? undefined }
    ].filter(item => !!item?.val);
};

export const miscellaneousData = (realEstate: RealEstate) => {
    return [
        {
            key: "Steuer-ID",
            val: realEstate?.notary?.taxId ?? undefined
        },
        { key: "Eigentümer handelt aus eigenem wirtschaftlichen Interesse", val: realEstate?.notary?.ownInterest ? "Ja" : "Nein" ?? undefined },
        {
            key: "Politisch exponierte Person",
            val: realEstate?.notary?.politicalPerson ? "Ja" : "Nein" ?? undefined
        }
    ].filter(item => !!item?.val);
};
