import { FC } from "react";
import { View } from "@react-pdf/renderer";
import { PageHeader } from "./page-header";
import { PageFooter } from "./page-footer";
import { RealEstate } from "@onpreo/database/src/definitions/real-estate";
import { User } from "@onpreo/database";

export interface PageLayoutProps {
    realEstate?: RealEstate;
    workspace?: any;
    logoSrc?: string;
    user?: User;
    type?: "clean" | "modern";
}

export const PageLayout: FC<PageLayoutProps> = ({ ...props }) => {
    return (
        <View>
            <PageHeader {...props} />
            {props.children}
            <PageFooter {...props} />
        </View>
    );
};
