import React, { Fragment, useEffect, useState } from "react";
import { RealEstateProps } from "../src/functions/props-factory";
import ExposeService from "../src/components/expose-service";
import { useDispatch } from "react-redux";
import { setRealEstate } from "../src/store/slices/real-estate.slice";
import { CircularProgress, Grid, Typography } from "@material-ui/core";
import realEstateService from "../src/store/services/real-estate";
import { Font } from "@react-pdf/renderer";
import { setUser } from "../src/store/slices/user";

export type IndexProps = { type: "SUCCESS"; props: RealEstateProps } | { type: "ERROR"; error: any };

const ExposeAssistantLoader = () => {
    return (
        <Grid container item direction="column" justifyContent={"center"} alignItems={"center"} style={{ marginTop: 48 }}>
            <Typography>Exposé Generator wird gestartet</Typography>
            <br />
            <CircularProgress />
        </Grid>
    );
};

const Index = () => {
    const dispatch = useDispatch();
    const [loading, setIsLoading] = useState(false);
    const [type, setType] = useState(undefined);

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop as string)
        });

        const documentId = (params as any)?.re;
        if (!type && documentId) {
            (async () => {
                setIsLoading(true);
                const response = await realEstateService.getInitialRealEstate(documentId);
                setType(response.data.props?.type);
                dispatch(setRealEstate(response.data.props.props));
                dispatch(setUser(response?.data?.props?.props?.responsibleUser));
                setIsLoading(false);
            })();
        }
    }, []);

    Font.register({
        family: "Poppins",
        fonts: [
            { src: `/fonts/Poppins-Regular.ttf`, fontWeight: "normal" },
            { src: `/fonts/Poppins-Medium.ttf`, fontWeight: "medium" },
            { src: `/fonts/Poppins-SemiBold.ttf`, fontWeight: "semibold" },
            { src: `/fonts/Poppins-Bold.ttf`, fontWeight: "bold" }
        ]
    });

    Font.registerHyphenationCallback(word => [word]);

    return (
        <Fragment>
            {type === "SUCCESS" && !loading && <ExposeService />}
            {type !== "SUCCESS" && !loading && (
                <Grid container item justifyContent={"center"} alignItems={"center"} style={{ marginTop: 48 }}>
                    <Typography>Sie haben nicht die nötigen Rechte, um diese Ressource zu nutzen</Typography>
                </Grid>
            )}
            {loading && <ExposeAssistantLoader />}
        </Fragment>
    );
};

export default Index;
