import { getDefaultBorderIncludes } from "./utils";
import { TableRow, TableRowProps } from "./table-row";
import { Children, cloneElement, FC } from "react";

export interface TableHeaderProps extends TableRowProps {}

/**
 * This component displays the titles for the rows.
 */
export const TableHeader: FC<TableHeaderProps> = props => {
    const { includeLeftBorder, includeBottomBorder, includeRightBorder, includeTopBorder } = getDefaultBorderIncludes(props);
    const rowCells: any[] = Children.toArray(props.children);

    return (
        <TableRow
            {...props}
            key={"header"}
            includeLeftBorder={includeLeftBorder}
            includeBottomBorder={includeBottomBorder}
            includeRightBorder={includeRightBorder}
            includeTopBorder={includeTopBorder}
        >
            {rowCells.map((rc, columnIndex) =>
                cloneElement(rc, {
                    key: columnIndex,
                    isHeader: true,
                    fontSize: props.fontSize,
                    textAlign: props.textAlign,
                    includeLeftBorder: columnIndex === 0,
                    includeRightBorder: columnIndex !== rowCells.length - 1
                })
            )}
        </TableRow>
    );
};
