import { Document } from "@react-pdf/renderer";
import ContactDetailsModern from "./contact-details-modern";
import CoverModern from "./cover-modern";
import DataOverviewModern from "./data-overview-modern";
import DescriptionModern from "./description-modern";
import ImpressionsModern from "./impressions-modern";
import RoomTable from "./room-table";

const NewDocument = ({ realEstate, workspace, generalData, energyData, logoSrc, user, mapSrc, roomTableData }) => {
    return (
        <Document>
            <CoverModern realEstate={realEstate} workspace={workspace} logoSrc={logoSrc} user={user} />
            <DataOverviewModern realEstate={realEstate} workspace={workspace} data={generalData} logoSrc={logoSrc} user={user} />
            <DescriptionModern realEstate={realEstate} workspace={workspace} data={energyData} logoSrc={logoSrc} user={user} mapSrc={mapSrc} />
            <ImpressionsModern realEstate={realEstate} workspace={workspace} logoSrc={logoSrc} user={user} />
            {realEstate?.expose?.roomTable?.show && roomTableData.length > 0 && (
                <RoomTable realEstate={realEstate} workspace={workspace} logoSrc={logoSrc} user={user} roomTableData={roomTableData} />
            )}
            <ContactDetailsModern realEstate={realEstate} workspace={workspace} logoSrc={logoSrc} user={user} />
        </Document>
    );
};

export default NewDocument;
