import { View, StyleSheet } from "@react-pdf/renderer";
import { PdfImage, SmallText } from "@onpreo/pdf-components";
import { ExposePage } from "@onpreo/pdf-components";
import React from "react";
import { Typo } from "@onpreo/pdf-components";
import { CurrencyShort, replaceSpaces } from "../../utils/formatters";

const styles = StyleSheet.create({
    provision: {
        marginTop: 40
    },
    half: {
        width: "49%"
        // backgroundColor: "purple"
    },
    flex: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    },
    iconGrid: {
        width: "10%",
        alignItems: "center"
    },
    textGrid: {
        alignItems: "center",
        marginTop: 24
        // marginLeft: 10
    },
    salutationText: {
        fontSize: 18,
        marginLeft: -10,

        color: "black",
        fontFamily: "Poppins",
        fontWeight: "semibold"
    },
    genderText: { fontSize: 18, color: "black", fontFamily: "Poppins", fontWeight: "medium", marginLeft: -10 },
    note: {
        marginBottom: 12,
        marginTop: 10,
        color: "black",
        fontFamily: "Poppins",
        fontWeight: "semibold"
    },
    subHeader: {
        fontSize: 20,
        color: "black",
        fontFamily: "Poppins",
        fontWeight: "semibold"
    },
    legalNote: {
        position: "absolute",
        bottom: 16
    }
});

const ContactDetailsModern = props => {
    const responsibleUser = props.user;
    const publication = props.realEstate.workspace?.publication;
    const companyInfo = props.realEstate.workspace?.companyInfo;

    const agentImage = responsibleUser?.meta?.img?.src ?? props.user?.workspace?.agentImage?.src;
    const town = companyInfo?.address?.zip + " " + companyInfo?.address?.town;
    const phone = responsibleUser?.meta?.phone ?? publication?.phone;
    const email = responsibleUser?.email ?? publication?.email;
    const aboutCompany = props.workspace?.landingPageInfo?.aboutUs;
    const website = companyInfo?.website;
    const name = responsibleUser?.meta?.name;
    const hasRealEstateFixedProvision = props.realEstate?.hasFixedProvision;
    const hasFixedProvision = props.workspace?.agentSettings?.hasFixedProvision;
    const workspaceProvision = props.workspace?.agentSettings?.provisionRate;

    const buyerRateRealEstate = hasRealEstateFixedProvision ? props.realEstate?.fixedProvision : props.realEstate?.provisionRate?.buyerCommission;
    const sellerRateRealEstate = hasRealEstateFixedProvision ? props.realEstate?.fixedProvision : props.realEstate?.provisionRate?.sellerCommission;
    const buyerRateWorkspace = hasFixedProvision ? props.workspace?.agentSettings?.fixedProvision : workspaceProvision?.buyerCommission ?? 2.49;
    const sellerRateWorkspace = hasFixedProvision ? props.workspace?.agentSettings?.fixedProvision : workspaceProvision?.sellerCommission ?? 2.49;
    const summaryProvisionRate = hasRealEstateFixedProvision
        ? props.realEstate?.fixedProvision
        : !hasRealEstateFixedProvision && !buyerRateRealEstate && !sellerRateRealEstate && hasFixedProvision
        ? props.workspace?.agentSettings?.fixedProvision
        : +Number(buyerRateRealEstate ?? buyerRateWorkspace).toFixed(2);
    const currencySymbol = props.realEstate?.askingPrice?.currency === "CHF" ? " CHF" : " €";
    const getSalutation = () => {
        let result;
        if (responsibleUser) {
            responsibleUser?.gender === "Frau"
                ? (result = "Ihre Ansprechpartnerin")
                : responsibleUser?.gender === "Herr"
                ? (result = "Ihr Ansprechpartner")
                : (result = "Ihr/e Ansprechpartner/-in");
        } else {
            publication?.salutation === "Frau"
                ? (result = "Ihre Ansprechpartnerin")
                : publication?.salutation === "Herr"
                ? (result = "Ihr Ansprechpartner")
                : (result = "Ihr/e Ansprechpartner/-in");
        }

        return result;
    };

    const getBrokerGender = () => {
        let result;
        if (responsibleUser) {
            responsibleUser?.gender === "Frau" ? (result = "Maklerin") : responsibleUser?.gender === "Herr" ? (result = "Makler") : (result = "Makler/-in");
        } else {
            publication?.salutation === "Frau" ? (result = "Maklerin") : publication?.salutation === "Herr" ? (result = "Makler") : (result = "Makler/-in");
        }

        return result;
    };

    const contactObject = { email: ["/email.png", email], contact: ["/contact.jpg", phone], website: ["/website.png", website] };
    const provisionValue = hasRealEstateFixedProvision
        ? `${CurrencyShort(props.realEstate?.fixedProvision)} `
        : !hasRealEstateFixedProvision && !buyerRateRealEstate && !sellerRateRealEstate && hasFixedProvision
        ? `${CurrencyShort(props.workspace?.agentSettings?.fixedProvision)} `
        : ` ${summaryProvisionRate} `;

    const percentageOrCurrency =
        hasRealEstateFixedProvision || (!hasRealEstateFixedProvision && !buyerRateRealEstate && !sellerRateRealEstate && hasFixedProvision)
            ? currencySymbol
            : "%";

    return (
        <ExposePage {...props}>
            <View style={styles.flex}>
                <View style={styles.half}>
                    <PdfImage
                        style={{
                            width: 200,
                            height: 330,
                            maxWidth: 200,
                            maxHeight: 330,
                            position: "relative",
                            border: "2px solid white",
                            overflow: "hidden"
                        }}
                        src={agentImage}
                    />
                </View>
                <View style={styles.half}>
                    <Typo style={styles.salutationText}> {getSalutation()} </Typo>
                    <Typo style={styles.genderText}> {name} </Typo>
                    <Typo style={{ marginBottom: 8, marginTop: 24, fontFamily: "Poppins" }}>{aboutCompany}</Typo>
                    <Typo style={styles.note}>Nehmen Sie jetzt Kontakt zu mir auf und vereinbaren Sie einen Besichtigungstermin</Typo>
                    {Object.keys(contactObject).map((key, index) => {
                        return (
                            <View style={{ ...styles.flex, marginBottom: -25 }} key={index}>
                                <View style={styles.iconGrid}>
                                    {!!contactObject[key][1] && (
                                        <PdfImage
                                            style={{ marginBottom: 8, marginTop: 24, fontWeight: "bold", height: 20, width: 20 }}
                                            src={contactObject[key][0]}
                                        />
                                    )}
                                </View>
                                <View style={styles.textGrid}>
                                    <Typo style={{ fontFamily: "Poppins" }}> {replaceSpaces(contactObject[key][1])}</Typo>
                                </View>
                            </View>
                        );
                    })}
                </View>
            </View>
            <View style={styles.provision} fixed>
                <Typo style={styles.subHeader}>Käuferprovision</Typo>
                <Typo style={{ textAlign: "left", marginTop: 10, fontFamily: "Poppins" }}>
                    {`Die Courtage in Höhe von ${provisionValue}${percentageOrCurrency} inkl. der gesetzlichen Mehrwertsteuer auf den Kaufpreis ist mit dem Zustandekommen des Kaufvertrages (notarieller Vertragsabschluss) verdient und fällig.`}
                </Typo>
            </View>
            <View style={styles.legalNote} fixed>
                <Typo style={{ fontFamily: "Poppins" }}>Rechtshinweis</Typo>
                <SmallText style={{ textAlign: "left", fontFamily: "Poppins" }}>
                    Da wir Objektangaben nicht selbst ermitteln, übernehmen wir hierfür keine Gewähr. Dieses Exposé ist nur für Sie persönlich bestimmt.
                    Eine Weitergabe an Dritte ist an unsere ausdrückliche Zustimmung gebunden und unterbindet nicht unseren Provisionsanspruch bei
                    Zustandekommen eines Vertrages. Alle Gespräche sind über unser Büro zu führen. Bei Zuwiderhandlung behalten wir uns Schadenersatz bis
                    zur Höhe der Provisionsansprüche ausdrücklich vor. Zwischenverkauf ist nicht ausgeschlossen.
                </SmallText>
            </View>
        </ExposePage>
    );
};

export default ContactDetailsModern;
