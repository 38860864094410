import { DataTableCell, Table, TableBody, ExposePage, SubTitle } from "@onpreo/pdf-components";

const DataOverview = props => {
    return (
        <ExposePage {...props}>
            <SubTitle>Immobilien Daten</SubTitle>
            <Table data={props.data}>
                <TableBody renderTopBorder={false}>
                    <DataTableCell weighting={0.5} getContent={r => r.key} />
                    <DataTableCell weighting={0.5} getContent={r => r.val} />
                </TableBody>
            </Table>
        </ExposePage>
    );
};

export default DataOverview;
