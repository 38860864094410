import { View, StyleSheet } from "@react-pdf/renderer";
import { PdfImage } from "@onpreo/pdf-components";
import { ExposePage } from "@onpreo/pdf-components";
import { getSignatureByUser } from "@onpreo/toolbox/src/client";
import React from "react";
import { SmallText, SubTitle, Typo } from "@onpreo/pdf-components";

const styles = StyleSheet.create({
    title: {
        // color: initData.primaryColor,
        fontSize: 36,
        fontWeight: "bold",
        paddingBottom: 12,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    subTitle: {
        fontSize: 20,
        paddingBottom: 8,
        width: "100%"
        // color: initData.primaryColor
    },
    text: {
        display: "flex",
        fontSize: 12,
        width: "auto"
    },
    smallText: {
        display: "flex",
        // color: initData.primaryColor,
        fontSize: 10,
        width: "auto"
    },
    legalNote: {
        position: "absolute",
        bottom: 16
    },
    half: {
        width: "49%"
        // backgroundColor: "purple"
    },
    flex: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap"
    }
});

const ContactDetails = props => {
    const responsibleUser = props.user;
    const publication = props.realEstate.workspace?.publication;

    const { name: userName, image, email: userEmail, company, address, phone: userPhone } = getSignatureByUser(responsibleUser);

    const name = userName ?? publication?.firstName + " " + publication?.lastName;
    const street = address?.street + " " + address?.house_number;
    const town = address?.zip + " " + address?.town;
    const phone = userPhone ?? publication?.phone;
    const email = userEmail ?? publication?.email;
    const getSalutation = () => {
        let result;
        if (responsibleUser) {
            responsibleUser?.gender === "Frau"
                ? (result = "Ihre Ansprechpartnerin")
                : responsibleUser?.gender === "Herr"
                ? (result = "Ihr Ansprechpartner")
                : (result = "Ihr/e Ansprechpartner/-in");
        } else {
            publication?.salutation === "Frau"
                ? (result = "Ihre Ansprechpartnerin")
                : publication?.salutation === "Herr"
                ? (result = "Ihr Ansprechpartner")
                : (result = "Ihr/e Ansprechpartner/-in");
        }

        return result;
    };

    return (
        <ExposePage {...props}>
            <View>
                <SubTitle>{getSalutation()}</SubTitle>
            </View>
            <View style={styles.flex}>
                <View style={styles.half}>
                    <Typo style={{ marginBottom: 8, marginTop: 24, fontWeight: "bold" }}>{name}</Typo>
                    <Typo>{company}</Typo>
                    <Typo>{street}</Typo>
                    <Typo style={{ marginBottom: 8 }}>{town}</Typo>
                    <Typo>Telefon: {phone}</Typo>
                    <Typo>E-Mail: {email}</Typo>
                </View>
                <View style={styles.half}>
                    <PdfImage
                        style={{
                            width: 160,
                            height: 160,
                            maxWidth: 160,
                            maxHeight: 160,
                            position: "relative",
                            borderRadius: 320,
                            border: "2px solid white",
                            overflow: "hidden",
                            marginLeft: 80
                        }}
                        src={image?.src}
                    />
                </View>
            </View>
            <View style={styles.legalNote} fixed>
                <Typo>Rechtshinweis</Typo>
                <SmallText style={{ textAlign: "left" }}>
                    Da wir Objektangaben nicht selbst ermitteln, übernehmen wir hierfür keine Gewähr. Dieses Exposé ist nur für Sie persönlich bestimmt.
                    Eine Weitergabe an Dritte ist an unsere ausdrückliche Zustimmung gebunden und unterbindet nicht unseren Provisionsanspruch bei
                    Zustandekommen eines Vertrages. Alle Gespräche sind über unser Büro zu führen. Bei Zuwiderhandlung behalten wir uns Schadenersatz bis
                    zur Höhe der Provisionsansprüche ausdrücklich vor. Zwischenverkauf ist nicht ausgeschlossen.
                </SmallText>
            </View>
        </ExposePage>
    );
};

export default ContactDetails;
