import ReactPDF, { Image } from "@react-pdf/renderer";
import React, { FC } from "react";

const styles = {
    image: {
        maxWidth: "17.8vw",
        maxHeight: 90,
        objectFit: "cover"
    }
};

export const PdfImage: FC<ReactPDF.ImageWithSrcProp> = ({ src, style }) => {
    return (
        <Image
            style={{ ...styles.image, ...style }}
            src={{ uri: src + "?noCache=" + Math.random().toString(), method: "GET", headers: { "Cache-Control": "no-cache" }, body: undefined }}
        />
    );
};

export const PdfMapImage: FC<ReactPDF.ImageWithSrcProp> = ({ src, style }) => {
    console.log(src);
    return <Image style={{ ...styles.image, ...style }} src={src} />;
};
