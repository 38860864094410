import { Box } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

const StickyHeader = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    position: "sticky",
    top: 0,
    left: 0,
    width: "100%",
    zIndex: 10,
    backgroundColor: "#f8f9fe",
    paddingBottom: 16
});

export default StickyHeader;
