import { DataTableCell, Table, TableBody, ExposePage, SubTitle } from "@onpreo/pdf-components";

const NotaryMiscellaneousPage = props => {
    return (
        <ExposePage {...props}>
            <SubTitle style={{ paddingTop: 20 }}>Kontaktdaten</SubTitle>
            <Table data={props.contactData}>
                <TableBody renderTopBorder={true}>
                    <DataTableCell weighting={0.5} getContent={r => r.key} />
                    <DataTableCell weighting={0.5} getContent={r => r.val} />
                </TableBody>
            </Table>
            <SubTitle style={{ paddingTop: 20 }}>Sonstiges</SubTitle>
            <Table data={props.miscellaneousData}>
                <TableBody renderTopBorder={true}>
                    <DataTableCell weighting={0.5} getContent={r => r.key} />
                    <DataTableCell weighting={0.5} getContent={r => r.val} />
                </TableBody>
            </Table>
        </ExposePage>
    );
};

export default NotaryMiscellaneousPage;
