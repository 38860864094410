import { DataTableCell, Table, TableBody, ExposePage, SubTitle } from "@onpreo/pdf-components";

const NotaryDataPage = props => {
    const padding = props.generalData.length <= 4 ? 60 : 20;
    return (
        <ExposePage {...props}>
            <SubTitle>Allgemeine Daten zum Eigentümer</SubTitle>
            <Table data={props.generalData}>
                <TableBody renderTopBorder={true}>
                    <DataTableCell weighting={0.5} getContent={r => r.key} />
                    <DataTableCell weighting={0.5} getContent={r => r.val} />
                </TableBody>
            </Table>
            <SubTitle style={{ paddingTop: padding }}>Persönliche Daten</SubTitle>
            <Table data={props.personalData}>
                <TableBody renderTopBorder={true}>
                    <DataTableCell weighting={0.5} getContent={r => r.key} />
                    <DataTableCell weighting={0.5} getContent={r => r.val} />
                </TableBody>
            </Table>
            <SubTitle style={{ paddingTop: padding }}>Adresse</SubTitle>
            <Table data={props.addressData}>
                <TableBody renderTopBorder={true}>
                    <DataTableCell weighting={0.5} getContent={r => r.key} />
                    <DataTableCell weighting={0.5} getContent={r => r.val} />
                </TableBody>
            </Table>
        </ExposePage>
    );
};

export default NotaryDataPage;
