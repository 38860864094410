import React, { useState, Fragment, useMemo, FC } from "react";
import { Card, CardContent, Divider, Grid, makeStyles, Typography, ButtonBase, Tooltip } from "@material-ui/core";
import { Edit, KeyboardArrowDown, KeyboardArrowUp, InfoOutlined } from "@material-ui/icons";
import { showComponentByType } from "./show-component";
import { infoStyles } from "./styles";
import { Control, DeepRequired, FieldErrorsImpl, UseFormGetValues, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { ExposeData, RealEstate, WorkspaceDocument } from "@onpreo/database";

const useStyles = makeStyles(infoStyles);

export type ControlExpose = {
    description: string;
    notes: {
        description: string;
        furnishing: string;
        location: string;
        other: string;
    };
    designType: "modern" | "clean";
    exposeFields: object;
    extras: WorkspaceDocument["extras"];
    propertyPhotos: ExposeData["propertyPhotos"];
    showMap: ExposeData["showMap"];
    roomTable: ExposeData["roomTable"];
    overviewPic: ExposeData["overviewPic"];
    aiDescriptionsWordCount: RealEstate["aiDescriptionsWordCount"];
};

interface CardLoadedProps {
    control: Control<ControlExpose, any>;
    cardType: string;
    watch: UseFormWatch<ControlExpose>;
    setValue: UseFormSetValue<ControlExpose>;
    getValues: UseFormGetValues<ControlExpose>;
    errors: FieldErrorsImpl<DeepRequired<ControlExpose>>;
}

const CardLoader: FC<CardLoadedProps> = ({ cardType, control, watch, setValue, getValues, errors }) => {
    const classes = useStyles();

    const [openInfoView, setOpenInfoView] = useState(false);

    const toggleInfoView = () => setOpenInfoView(!openInfoView);

    const currentCard = useMemo(() => showComponentByType(cardType), [cardType]);

    return (
        <Card className={classes.infoCard} style={{ maxHeight: openInfoView ? "100%" : 60 }}>
            <ButtonBase disableRipple onClick={toggleInfoView}>
                <CardContent className={classes.cardContent}>
                    <Grid container justifyContent="space-between" className={classes.headerGrid}>
                        <Grid container>
                            <Grid item>
                                <Typography className={classes.headerText}>
                                    {currentCard.title}{" "}
                                    {currentCard.info && (
                                        <Tooltip title={currentCard.info} classes={{ tooltip: classes.customTooltipWidth }}>
                                            <InfoOutlined className={classes.infoIcon} />
                                        </Tooltip>
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item>
                                {openInfoView ? (
                                    <KeyboardArrowUp style={{ width: 24, height: 24 }} />
                                ) : (
                                    <KeyboardArrowDown style={{ width: 24, height: 24 }} />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </ButtonBase>

            {openInfoView && (
                <Fragment>
                    <Divider className={classes.divider} />
                    <CardContent className={classes.cardContent}>{currentCard.cardComponent({ control, watch, setValue, getValues, errors })}</CardContent>
                </Fragment>
            )}
        </Card>
    );
};

export default CardLoader;
