import { ImageDetails } from "@onpreo/database/src";
import { imageHandler } from "@onpreo/toolbox/src/client/image-handler";
import { onpreoClient } from "@onpreo/upsy-daisy/client";
import { replaceUmlaut, searchParams } from "../../utils/formatters";
import { Category } from "../../components/data-editing/photo-upload/photo-accordion";

const getInitialRealEstate = async documentId => {
    const url = `/api/real-estate/${documentId}${searchParams()}`;
    return await onpreoClient.get(url);
};

const updateRealEstate = async (documentId, document) => {
    const url = `/api/real-estate/${documentId}`;
    return await onpreoClient.put(url, { documentId, document });
};

const uploadAsset = async (id: string, file: Blob, fileName?: string, isSecure?: boolean) => {
    const url = `/api/real-estate/${id}/asset`;
    const descriptor = { originalFilename: replaceUmlaut(fileName ?? (file as any)?.name), mimetype: file.type };
    const res = await onpreoClient.post(url, { ...descriptor, isSecure });
    await imageHandler.uploadPreSignedFile(res.data.details.presigned, file, isSecure);
    return res;
};

const removeAsset = async (id: string, details: ImageDetails, isSecure?: boolean) => {
    const url = `/api/real-estate/${id}/remove-asset`;
    return await onpreoClient.delete(url, { data: { ...details, isSecure } });
};

const addLogoWatermark = async (src: string, id: string, logoPosition: string, opacity: number) => {
    const url = `/api/real-estate/watermark`;
    const response = await onpreoClient.get(url, { responseType: "arraybuffer", params: { src, id, logoPosition, opacity } });
    return response;
};

const savePhotosOrder = async (realEstateId: string, value: Category[]) => {
    const url = `/api/real-estate/${realEstateId}`;
    return await onpreoClient.post(url, { value });
};

const updatePropertyPhotos = async (id, document) => {
    const url = `/api/real-estate/${id}/property-photos`;
    return await onpreoClient.patch(url, { document });
};

const updateExposeFields = async (id, value) => {
    const url = `/api/real-estate/${id}/expose-fields`;
    return await onpreoClient.put(url, { id, value });
};

const getRealEstateValuationDocument = async (realEstateId: string) => {
    const url = `/api/real-estate/get-valuation`;
    return await onpreoClient.get(url, { params: { realEstateId: realEstateId } });
};

const createDescriptionTexts = async (realEstateId: string, aiDescriptionsWordCount: any) => {
    const url = `/api/real-estate/${realEstateId}/descriptions${searchParams()}`;
    return await onpreoClient.get(url, { params: { aiDescriptionsWordCount } });
};
const createDescriptionVariantText = async (
    realEstateId: string,
    variant: "title" | "description" | "furnishing" | "location" | "other",
    aiDescriptionsWordCount: any
) => {
    const url = `/api/real-estate/${realEstateId}/descriptions${searchParams()}`;
    return await onpreoClient.post(url, { variant, aiDescriptionsWordCount });
};

const updateRoomData = async (id, value) => {
    const url = `/api/real-estate/${id}/room-data`;
    return await onpreoClient.put(url, { id, value });
};

const realEstateService = {
    getInitialRealEstate,
    updateRealEstate,
    uploadAsset,
    removeAsset,
    addLogoWatermark,
    savePhotosOrder,
    updatePropertyPhotos,
    updateExposeFields,
    getRealEstateValuationDocument,
    createDescriptionTexts,
    createDescriptionVariantText,
    updateRoomData
};

export default realEstateService;
