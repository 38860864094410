import { Grid, Typography, CircularProgress, Dialog, makeStyles, createStyles } from "@material-ui/core";
import { FC } from "react";

const useDialogStyles = makeStyles(theme =>
    createStyles({
        paper: { minWidth: "712px", minHeight: "320px", overflow: "hidden" }
    })
);

interface LoadingDialogProps {
    open: boolean;
    onClose: () => void;
}

const LoadingDialog: FC<LoadingDialogProps> = ({ open, onClose }) => {
    const classes = useDialogStyles();

    return (
        <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
            <Grid container justifyContent="center" alignItems="center" direction="column" style={{ minHeight: "320px" }}>
                <Grid item style={{ marginTop: -80, marginBottom: 50 }}>
                    <Typography variant="h2" align="center">
                        Ihr PDF-Dokument wird hochgeladen
                    </Typography>
                    <Typography variant="h3" align="center">
                        Bitte schließen Sie nicht das Fenster.
                    </Typography>
                </Grid>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default LoadingDialog;
