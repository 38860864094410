import dynamic from "next/dynamic";
const ImpressionsEditing = dynamic(() => import("./data-editing/impressions-editing"));
const DataFieldsEditing = dynamic(() => import("./data-editing/data-fields-editing"));
const DescriptionEditing = dynamic(() => import("./data-editing/description-editing"));
const Design = dynamic(() => import("./data-editing/design"));

const INFO_TEXT = {
    PICTURES:
        "Lande Sie in dieser Sektion all Bilder, welche im Exposé erscheinen sollen hoch. Durch Drag&Drop können Sie die Reihenfolge der einzelnen Kategorien anpassen. Bis auf die Highlight-Kategorie, welche immer auf der ersten Seite des Exposés angezeigt wird, können Sie jede Kategorie beliebig anordnen."
};

export const showComponentByType = cardType => {
    switch (cardType) {
        case "design":
            return {
                title: "Design",
                cardComponent: (props?: any) => <Design {...props} />
            };
        case "descriptions":
            return {
                title: "Beschreibung",
                cardComponent: (props?: any) => <DescriptionEditing {...props} />
            };
        case "pictures":
            return {
                title: "Impressionen",
                info: INFO_TEXT.PICTURES,
                cardComponent: (props?: any) => <ImpressionsEditing {...props} />
            };
        case "data":
            return {
                title: "Daten",
                cardComponent: (props?: any) => <DataFieldsEditing {...props} />
            };

        default:
            return undefined;
    }
};
