import { Button, ButtonProps, CircularProgress, Tooltip, createStyles, makeStyles } from "@material-ui/core";
import { FC, useState } from "react";

const useStyles = makeStyles(theme =>
    createStyles({
        tooltip: {
            maxWidth: 300
        }
    })
);

interface LoadingButtonProps extends Omit<ButtonProps, "onClick"> {
    onClick: () => Promise<any> | void;
    isRedirect?: boolean;
    timeout?: number;
    tooltip?: boolean;
    title?: string;
    isSecondary?: boolean;
}

export const LoadingButton: FC<LoadingButtonProps> = ({ onClick, isRedirect = false, timeout = 500, tooltip, title, isSecondary, ...props }) => {
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();

    const handleAsyncLoading = async (asyncFunction: any) => {
        setIsLoading(true);
        setTimeout(() => {
            (async () => {
                await asyncFunction();
                if (!isRedirect) setIsLoading(false);
            })();
        }, timeout);
    };

    return (
        <>
            {tooltip && (
                <Tooltip title={title} classes={{ tooltip: classes.tooltip }}>
                    <span>
                        <Button {...props} onClick={() => handleAsyncLoading(onClick)} disabled={props.disabled || isLoading}>
                            {isLoading && <CircularProgress color={isSecondary ? "secondary" : "primary"} size={"1em"} />}
                            {!isLoading && props.children}
                        </Button>
                    </span>
                </Tooltip>
            )}
            {!tooltip && (
                <Button {...props} onClick={() => handleAsyncLoading(onClick)} disabled={props.disabled || isLoading}>
                    {isLoading && <CircularProgress color={isSecondary ? "secondary" : "primary"} size={"1em"} />}
                    {!isLoading && props.children}
                </Button>
            )}
        </>
    );
};
