import { Category } from "@onpreo/database";
import { createAppAsyncThunk } from "..";
import workspaceService from "../services/workspace";
import { setUser } from "../slices/user";

export interface UpdateWorkspace {
    workspaceId: string;
    value: string;
    caption: string;
    logoPosition: string;
    opacity: number;
}

export const $updateExtras = createAppAsyncThunk(
    "workspace/$updateLogoSize",
    async ({ workspaceId, value, caption, logoPosition, opacity }: UpdateWorkspace, thunkAPI) => {
        try {
            const response = await workspaceService.updateWorkspace(workspaceId, value, caption, logoPosition, opacity);
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }
);

export const $createLocationDraft = createAppAsyncThunk(
    "workspace/$createLocationDraft",
    async ({ id, data }: { id: string; data: { title: string; description: string } }, thunkAPI) => {
        const response = await workspaceService.createLocationDraft(id, data);
        thunkAPI.dispatch(setUser(response.data.user));
        return response.data;
    }
);

export const $deleteLocationDraft = createAppAsyncThunk(
    "workspace/$deleteLocationDraft",
    async ({ id, draftId }: { id: string; draftId: string }, thunkAPI) => {
        const response = await workspaceService.deleteLocationDraft(id, draftId);
        thunkAPI.dispatch(setUser(response.data.user));
        return response.data;
    }
);
