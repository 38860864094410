import { View } from "@react-pdf/renderer";
import { ExposePage, SubTitle } from "@onpreo/pdf-components";
import { BigImages } from "./impressions";

const Highlights = ({ ...props }) => {
    const highlightPhotos = props.realEstate?.expose?.propertyPhotos?.highlights;
    const showCaption = props.realEstate?.workspace?.extras?.showCaption;

    return (
        <ExposePage {...props}>
            {highlightPhotos?.length > 0 && (
                <View wrap>
                    <SubTitle style={{ paddingBottom: 8, textAlign: "center" }}>Impressionen</SubTitle>
                    <BigImages imgs={highlightPhotos} showCaption={showCaption} isContained={false} />
                </View>
            )}
        </ExposePage>
    );
};

export default Highlights;
