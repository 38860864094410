import { FC } from "react";
import ReactPDF, { StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
    title: {
        fontFamily: "Helvetica",
        color: "gray",
        fontSize: 27,
        fontWeight: "bold",
        paddingBottom: 16,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    subTitle: {
        fontFamily: "Helvetica",
        fontSize: 20,
        paddingBottom: 16,
        width: "100%",
        color: "gray"
    },
    text: {
        fontFamily: "Helvetica",
        display: "flex",
        fontSize: 12,
        width: "auto"
    },
    smallText: {
        fontFamily: "Helvetica",
        display: "flex",
        fontSize: 10,
        width: "auto",
        textAlign: "center"
    }
});

export const Title: FC<ReactPDF.TextProps> = ({ style, ...props }) => (
    <Text style={{ ...styles.title, ...style }} {...props}>
        {props.children}
    </Text>
);

export const SubTitle: FC<ReactPDF.TextProps> = ({ style, ...props }) => (
    <Text style={{ ...styles.subTitle, ...style }} {...props}>
        {props.children}
    </Text>
);

export const Typo: FC<ReactPDF.TextProps> = ({ style, ...props }) => (
    <Text style={{ ...styles.text, ...style }} {...props}>
        {props.children}
    </Text>
);

export const SmallText: FC<ReactPDF.TextProps> = ({ style, ...props }) => (
    <Text style={{ ...styles.smallText, ...style }} {...props}>
        {props.children}
    </Text>
);
