import { CSSProperties } from "react";
// @ts-ignore
import { Area } from "react-easy-crop";

const createImage = (url: string) =>
    new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener("load", () => resolve(image));
        image.addEventListener("error", error => reject(error));
        image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
        image.src = url;
    });

function getRadianAngle(degreeValue: number) {
    return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {CSSProperties} filter - filter parameter
 * @param {number} rotation - optional rotation parameter
 */
export default async function getCroppedImg(
    imageSrc: string,
    pixelCrop: Area,
    filter: CSSProperties,
    type: string,
    rotation = 0,
    isRound: boolean,
    maxDimension?: number
): Promise<{ png: Blob }> {
    const image: any = await createImage(imageSrc);
    const translationCanvas = document.createElement("canvas");
    const tctx = translationCanvas.getContext("2d");

    const safeArea = Math.max(image.width, image.height) * 2;

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    translationCanvas.width = safeArea;
    translationCanvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    tctx!.translate(safeArea / 2, safeArea / 2);
    tctx!.rotate(getRadianAngle(rotation));
    tctx!.translate(-safeArea / 2, -safeArea / 2);

    tctx!.filter = filter.filter as string;

    // draw rotated image and store data.
    tctx!.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5);

    // Now we have drawn the image into the first canvas, applying the translations
    // We will now create a second canvas and scale the image to its final resolution (and maybe apply rounding)

    const resultCanvas = document.createElement("canvas");
    const ctx = resultCanvas.getContext("2d");

    const aspectRatio = pixelCrop.width / pixelCrop.height;
    let scaleFactor = 1;
    if (maxDimension !== undefined && pixelCrop.width > maxDimension) {
        // -> We will need to scale the final image down
        resultCanvas.width = maxDimension;
        scaleFactor = maxDimension / pixelCrop.width;
        resultCanvas.height = maxDimension / aspectRatio;
    } else if (maxDimension !== undefined && pixelCrop.height > maxDimension) {
        resultCanvas.width = maxDimension * aspectRatio;
        resultCanvas.height = maxDimension;
        scaleFactor = maxDimension / pixelCrop.height;
    } else {
        resultCanvas.width = pixelCrop.width;
        resultCanvas.height = pixelCrop.height;
    }

    // console.log("PIXEL CROP: ", pixelCrop);
    // console.log("WIDTH: ", resultCanvas.width);
    // console.log("HEIGHT: ", resultCanvas.height);

    if (isRound) {
        ctx!.save();
        ctx!.clearRect(0, 0, resultCanvas.width, resultCanvas.height);
        ctx!.beginPath();
        ctx!.arc(resultCanvas.width / 2, resultCanvas.height / 2, resultCanvas.width / 2, 0, Math.PI * 2);
        ctx!.clip();
    }

    ctx!.drawImage(
        translationCanvas,
        (0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x) * scaleFactor,
        (0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y) * scaleFactor,
        safeArea * scaleFactor,
        safeArea * scaleFactor
    );

    // As a blob
    return new Promise((resolve, reject) => {
        resultCanvas.toBlob(pngBlob => {
            if (pngBlob !== null) {
                resolve({ png: pngBlob });
            } else {
                reject();
            }
        }, "image/png");
    });
}
