import axios from "axios";

const updateWorkspace = async (workspaceId, value, caption, logoPosition, opacity) => {
    const url = `/api/workspace/`;
    return await axios.put(url, { workspaceId, value, caption, logoPosition, opacity });
};

const createLocationDraft = async (id, data) => {
    const url = `/api/workspace/${id}/workspace-location-drafts`;
    return await axios.post(url, data);
};

const deleteLocationDraft = async (id, draftId) => {
    const url = `/api/workspace/${id}/workspace-location-drafts`;
    return await axios.delete(url, { data: draftId });
};

const workspaceService = {
    updateWorkspace,
    createLocationDraft,
    deleteLocationDraft
};

export default workspaceService;
