import { Children, cloneElement, createElement, FC, ReactElement } from "react";
import { TableHeader } from "./table-header";
import { TableBody } from "./table-body";
import { View } from "@react-pdf/renderer";

export interface ZebraProps {
    /**
     * Whether to display alternate row colours.
     * Defaults to false.
     */
    zebra?: boolean;

    /**
     * The even row colour when {@see zebra} is true.
     * Defaults to lightgray if not defined.
     */
    evenRowColor?: string;

    /**
     * The odd row colour when {@see zebra} is true.
     * Defaults to transparent or '' if not defined.
     */
    oddRowColor?: string;
}

export interface TableProps extends ZebraProps {
    data?: any[];
    /**
     * Indicates that this is a nested table.
     * Otherwise assumed to be false.
     */
    isNested?: boolean;
}

export const Table: FC<TableProps> = props => {
    let tableHeader: ReactElement = null;
    let tableBody: ReactElement = null;

    Children.forEach(props.children, (c: ReactElement) => {
        if (c?.type === TableHeader) {
            tableHeader = c;
        } else if (c?.type === TableBody) {
            tableBody = c;
        }
    });

    if (tableBody === null) {
        tableBody = createElement(TableBody);
    }

    tableBody = cloneElement(tableBody, {
        data: tableBody?.props?.data ?? props.data ?? [],
        renderTopBorder: props.isNested ? false : !tableHeader,
        zebra: tableBody?.props?.zebra ?? props.zebra ?? false,
        evenRowColor: tableBody?.props?.evenRowColor ?? props.evenRowColor ?? "",
        oddRowColor: tableBody?.props?.oddRowColor ?? props.oddRowColor ?? ""
    });

    return (
        <View
            style={{
                width: "100%"
            }}
        >
            {tableHeader}
            {tableBody}
        </View>
    );
};
